import {
  ActivityLogActionTypes,
  IActivityLogsData,
  IAdminLogsGenericData,
  ILogsUserData,
  LogActionTypes,
  PerformerTypes,
  TimeFormat,
  TypeOfUser,
} from '@pharmaplan/common';
import {
  DynamicTableCell,
  OtherScreens,
  performerTypeMapper,
} from '../../../helpers/Constants';
import strings from '../../../localization';
import styles from './style';
import { getDayMonthDateYear, getHourMinute } from '../../Reports/helpers';
import { timeString } from '../../../helpers/Functions';
import {
  IPerformerProfileLinkSignature,
  IUserProfileLinkSignature,
} from '../../../hooks/Admin/useUserProfileLink';
import { getHoursDiff } from '../../Modals/WorkshiftDetails/utils';
import { convertDecimal } from '../Profile/Pharmacy/PharmacyViewProfile/helper';

const { text, customLink, chip, userAndType } = DynamicTableCell;

interface IRows {
  data: Array<IAdminLogsGenericData> | Array<IActivityLogsData>;
  timeFormat: TimeFormat;
  goToUserProfile?: IUserProfileLinkSignature;
  goToPerformerProfile: IPerformerProfileLinkSignature;
}

export const handleActions = {
  [LogActionTypes.booking]: {
    style: styles.booked,
    label: strings.booking,
  },
  [LogActionTypes.cancelBooking]: {
    style: styles.booked,
    label: strings.cancelBooking,
  },
  [LogActionTypes.cancelBookingRequest]: {
    style: styles.booked,
    label: strings.cancelBookingRequest,
  },
  [LogActionTypes.create]: {
    style: styles.posted,
    label: strings.create,
  },
  [LogActionTypes.delete]: {
    style: styles.booked,
    label: strings.delete,
  },
  [LogActionTypes.update]: {
    style: styles.requested,
    label: strings.update,
  },
  [LogActionTypes.updateTime]: {
    style: styles.requested,
    label: strings.updateTiming,
  },
  [LogActionTypes.updateRateAndAllowance]: {
    style: styles.requested,
    label: strings.updateRateAndAllowance,
  },
};

const handleActivityLogActions = {
  [ActivityLogActionTypes.Create]: {
    style: styles.posted,
  },
  [ActivityLogActionTypes.Update]: {
    style: styles.requested,
  },
  [ActivityLogActionTypes.Delete]: {
    style: styles.booked,
  },
  [ActivityLogActionTypes.Activate]: {
    style: styles.posted,
  },
  [ActivityLogActionTypes.Deactivate]: {
    style: styles.booked,
  },
  [ActivityLogActionTypes.Hide]: {
    style: styles.booked,
  },
  [ActivityLogActionTypes.Unhide]: {
    style: styles.posted,
  },
  [ActivityLogActionTypes.Approve]: {
    style: styles.posted,
  },
  [ActivityLogActionTypes.Reject]: {
    style: styles.booked,
  },
  [ActivityLogActionTypes.Cancel]: {
    style: styles.booked,
  },
  [ActivityLogActionTypes.Upload]: {
    style: styles.requested,
  },
  [ActivityLogActionTypes.AppointPharmacyAdministrator]: {
    style: styles.posted,
  },
  [ActivityLogActionTypes.RatePharmacist]: {
    style: styles.posted,
  },
  [ActivityLogActionTypes.AcceptBookingPharmacist]: {
    style: styles.posted,
  },
};

export const activityLogsHeader = () =>
  [
    {
      key: '0',
      label: strings.performer,
    },
    {
      key: '1',
      label: strings.user,
    },
    {
      key: '2',
      label: strings.action,
    },
    {
      key: '3',
      label: strings.actionDateTime,
    },
    {
      key: '4',
      label: strings.notes,
    },
  ];

export const workshiftLogsHeader = () =>
  [
    {
      key: '0',
      label: strings.code,
    },
    {
      key: '1',
      label: `${strings.date} | ${strings.time}`,
    },
    {
      key: '2',
      label: strings.performer,
    },
    {
      key: '3',
      label: strings.pharmacy,
    },
    {
      key: '4',
      label: strings.pharmacyHourlyRate,
    },
    {
      key: '5',
      label: strings.pharmacistHourlyRate,
    },
    {
      key: '6',
      label: strings.action,
    },
    {
      key: '7',
      label: strings.actionDateTime,
    },
    {
      key: '8',
      label: strings.notes,
    },
  ];

export const availabilityLogsHeader = () =>
  [
    {
      key: '0',
      label: strings.code,
    },
    {
      key: '1',
      label: `${strings.date} | ${strings.time}`,
    },
    {
      key: '2',
      label: strings.performer,
    },
    {
      key: '3',
      label: strings.pharmacist,
    },
    {
      key: '4',
      label: strings.hours,
      isCenterAligned: true,
    },
    {
      key: '5',
      label: strings.status,
    },
    {
      key: '6',
      label: strings.action,
    },
    {
      key: '7',
      label: strings.actionDateTime,
    },
    {
      key: '8',
      label: strings.notes,
    },
  ];

export const bookingLogsHeader = () =>
  [
    {
      key: '0',
      label: `${strings.date} | ${strings.time}`,
    },
    {
      key: '1',
      label: strings.performer,
    },
    {
      key: '2',
      label: strings.pharmacy,
    },
    {
      key: '3',
      label: strings.pharmacyHourlyRate,
    },
    {
      key: '4',
      label: strings.pharmacist,
    },
    {
      key: '5',
      label: strings.pharmacistHourlyRate,
    },
    {
      key: '6',
      label: strings.action,
    },
    {
      key: '7',
      label: strings.actionDateTime,
    },
    {
      key: '8',
      label: strings.notes,
    },
  ];

const renderUserColumn = (
  affectedCount: number | null,
  applicationActivityId: string,
  users: Array<ILogsUserData>,
) => {
  const userText = affectedCount === 1 ? strings.user : strings.users;

  if (affectedCount === null) {
    return {
      key: `10-${applicationActivityId}`,
      value: strings.allUsers,
      type: text,
    };
  }

  // More than 2 show only users count
  if (affectedCount > 2) {
    return {
      key: `11-${applicationActivityId}`,
      value: `${affectedCount} ${strings.users}`,
      type: text,
    };
  }

  // Handler for some cases (eg: perm delete) where there are no users in array but affected count is there
  if (users.length === 0 && affectedCount) {
    return {
      key: `11-${applicationActivityId}`,
      value: `${affectedCount} ${userText}`,
      type: text,
    };
  }

  return {
    key: `11-${applicationActivityId}`,
    value: `${affectedCount} ${userText}`,
    type: text,
  };
};

export const activityLogsRow = ({
  data,
  timeFormat,
  goToPerformerProfile,
}: IRows) =>
  (data as IActivityLogsData[]).map((item) => {
    const {
      applicationActivityId,
      performerName,
      performerUserType,
      performerPersonnelId,
      actionDate,
      action,
      notes,
      users,
      isUserActive,
      affectedCount,
      actionText,
      showCountOnly,
    } = item ?? {};

    const userAndTypeConfig = users.map((user) => {
      const {
        userId,
        personnelId,
        userName,
        userType,
        isUserActive: isActiveUser,
      } = user ?? {};

      const isAdmin = userType === PerformerTypes.admin
        || userType === PerformerTypes.superAdmin
        || userType === PerformerTypes.pharmacyAdmin;

      return {
        key: userId,
        userName,
        userType: performerTypeMapper[userType],
        disable: isAdmin,
        handlePress: goToPerformerProfile({
          personnelId,
          performerType: userType,
          fromScreen: OtherScreens.AdminReports,
          isUserActive: isActiveUser,
        }),
      };
    });

    const userColumn = () => {
      // Showcountonly is handled from back-end
      if (showCountOnly) {
        return renderUserColumn(affectedCount, applicationActivityId, users);
      }

      return {
        key: `1-${applicationActivityId}`,
        value: userAndTypeConfig,
        type: userAndType,
        maxWidth: 150,
      };
    };

    const isAdmin = performerUserType === PerformerTypes.admin
      || performerUserType === PerformerTypes.superAdmin
      || performerUserType === PerformerTypes.pharmacyAdmin;

    const formatActionDate = getDayMonthDateYear(actionDate);
    const actionTime = getHourMinute(actionDate, timeFormat);

    const actionDateTime = `${formatActionDate} | ${actionTime}`;
    const { style } = handleActivityLogActions[action] ?? {};

    return {
      key: applicationActivityId,
      data: [
        {
          key: `0-${applicationActivityId}`,
          value: [
            {
              key: performerPersonnelId,
              userName: performerName,
              userType: performerTypeMapper[performerUserType],
              disable: isAdmin,
              handlePress: goToPerformerProfile({
                personnelId: performerPersonnelId,
                performerType: performerUserType,
                fromScreen: OtherScreens.AdminReports,
                isUserActive,
              }),
            },
          ],
          type: userAndType,
          maxWidth: 150,
        },
        userColumn(),
        {
          key: `2-${applicationActivityId}`,
          value: {
            label: actionText,
            style,
          },
          type: chip,
        },
        {
          key: `3-${applicationActivityId}`,
          value: actionDateTime,
          type: text,
        },
        {
          key: `4-${applicationActivityId}`,
          value: notes,
          type: text,
        },
      ],
    };
  });

export const workshiftLogsRow = ({
  data,
  timeFormat,
  goToUserProfile,
  goToPerformerProfile,
}: IRows) =>
  (data as IAdminLogsGenericData[]).map((item) => {
    const {
      code,
      startDate,
      endDate,
      performerPersonnelId,
      performerName,
      userName,
      userPersonnelId,
      pharmacistHourlyRate,
      pharmacyHourlyRate,
      actionDate,
      action,
      notes,
      performerUserType,
      logId,
      isUserActive,
      isPerformerActive,
    } = item ?? {};

    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    const formatActionDate = getDayMonthDateYear(actionDate);
    const actionTime = getHourMinute(actionDate, timeFormat);

    const dateTime = `${date} | ${time}`;
    const actionDateTime = `${formatActionDate} | ${actionTime}`;

    const { label, style } = handleActions[action as LogActionTypes];

    const isAdmin = performerUserType === PerformerTypes.admin
      || performerUserType === PerformerTypes.superAdmin
      || performerUserType === PerformerTypes.pharmacyAdmin;

    return {
      key: logId,
      data: [
        {
          key: `0-${logId}`,
          value: code,
          type: text,
        },
        {
          key: `1-${logId}`,
          value: dateTime,
          type: text,
        },
        {
          key: `2-${logId}`,
          value: [
            {
              key: performerPersonnelId,
              userName: performerName,
              userType: performerTypeMapper[performerUserType],
              disable: isAdmin,
              handlePress: goToPerformerProfile({
                personnelId: performerPersonnelId,
                performerType: performerUserType,
                fromScreen: OtherScreens.AdminReports,
                isUserActive: isPerformerActive,
              }),
            },
          ],
          type: userAndType,
          maxWidth: 150,
        },
        {
          key: `3-${logId}`,
          value: {
            label: userName,
            handlePress: goToUserProfile?.({
              personnelId: userPersonnelId,
              userType: TypeOfUser.pharmacy,
              fromScreen: OtherScreens.AdminReports,
              isUserActive,
            }),
          },
          type: customLink,
          maxWidth: 150,
        },
        {
          key: `4-${logId}`,
          value: convertDecimal(pharmacyHourlyRate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `5-${logId}`,
          value: convertDecimal(pharmacistHourlyRate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `6-${logId}`,
          value: {
            label,
            style,
          },
          type: chip,
        },
        {
          key: `7-${logId}`,
          value: actionDateTime,
          type: text,
        },
        {
          key: `8-${logId}`,
          value: notes,
          type: text,
        },
      ],
    };
  });

export const availabilityLogsRow = ({
  data,
  timeFormat,
  goToUserProfile,
  goToPerformerProfile,
}: IRows) =>
  (data as IAdminLogsGenericData[]).map((item) => {
    const {
      code,
      startDate,
      endDate,
      performerName,
      userName,
      userPersonnelId,
      actionDate,
      action,
      notes,
      isBooked,
      performerUserType,
      logId,
      performerPersonnelId,
      isUserActive,
      isPerformerActive,
    } = item ?? {};

    const { label, style } = handleActions[action as LogActionTypes];

    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    const formatActionDate = getDayMonthDateYear(actionDate);
    const actionTime = getHourMinute(actionDate, timeFormat);

    const dateTime = `${date} | ${time}`;
    const actionDateTime = `${formatActionDate} | ${actionTime}`;

    const hours = getHoursDiff(startDate, endDate);

    const isAdmin = performerUserType === PerformerTypes.admin
      || performerUserType === PerformerTypes.superAdmin
      || performerUserType === PerformerTypes.pharmacyAdmin;

    return {
      key: logId,
      data: [
        {
          key: `0-${logId}`,
          value: code,
          type: text,
        },
        {
          key: `1-${logId}`,
          value: dateTime,
          type: text,
          maxWidth: 150,
        },
        {
          key: `2-${logId}`,
          value: [
            {
              key: performerPersonnelId,
              userName: performerName,
              userType: performerTypeMapper[performerUserType],
              disable: isAdmin,
              handlePress: goToPerformerProfile({
                personnelId: performerPersonnelId,
                performerType: performerUserType,
                fromScreen: OtherScreens.AdminReports,
                isUserActive: isPerformerActive,
              }),
            },
          ],
          type: userAndType,
        },
        {
          key: `3-${logId}`,
          value: {
            label: userName,
            handlePress: goToUserProfile?.({
              personnelId: userPersonnelId,
              userType: TypeOfUser.pharmacist,
              fromScreen: OtherScreens.AdminReports,
              isUserActive,
            }),
          },
          type: customLink,
        },
        {
          key: `4-${logId}`,
          value: hours,
          type: text,
          isCenterAligned: true,
        },
        {
          key: `5-${logId}`,
          value: isBooked ? strings.booked : strings.free,
          type: text,
        },
        {
          key: `6-${logId}`,
          value: {
            label,
            style,
          },
          type: chip,
        },
        {
          key: `7-${logId}`,
          value: actionDateTime,
          type: text,
        },
        {
          key: `8-${logId}`,
          value: notes,
          type: text,
        },
      ],
    };
  });

export const bookingLogsRow = ({
  data,
  timeFormat,
  goToUserProfile,
  goToPerformerProfile,
}: IRows) =>
  (data as IAdminLogsGenericData[]).map((item) => {
    const {
      startDate,
      endDate,
      performerName,
      userName,
      userPersonnelId,
      actionDate,
      action,
      notes,
      performerUserType,
      logId,
      performerPersonnelId,
      pharmacistName,
      pharmacistId,
      pharmacistHourlyRate,
      pharmacyHourlyRate,
      isPharmacistActive,
      isUserActive,
      isPerformerActive,
    } = item ?? {};

    const { label, style } = handleActions[action as LogActionTypes];

    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    const formatActionDate = getDayMonthDateYear(actionDate);
    const actionTime = getHourMinute(actionDate, timeFormat);

    const dateTime = `${date} | ${time}`;
    const actionDateTime = `${formatActionDate} | ${actionTime}`;

    const isAdmin = performerUserType === PerformerTypes.admin
      || performerUserType === PerformerTypes.superAdmin
      || performerUserType === PerformerTypes.pharmacyAdmin;

    return {
      key: logId,
      data: [
        {
          key: `0-${logId}`,
          value: dateTime,
          type: text,
          maxWidth: 150,
        },
        {
          key: `2-${logId}`,
          value: [
            {
              key: performerPersonnelId,
              userName: performerName,
              userType: performerTypeMapper[performerUserType],
              disable: isAdmin,
              handlePress: goToPerformerProfile({
                personnelId: performerPersonnelId,
                performerType: performerUserType,
                fromScreen: OtherScreens.AdminReports,
                isUserActive: isPerformerActive,
              }),
            },
          ],
          type: userAndType,
        },
        {
          key: `3-${logId}`,
          value: {
            label: userName,
            handlePress: goToUserProfile?.({
              personnelId: userPersonnelId,
              userType: TypeOfUser.pharmacy,
              fromScreen: OtherScreens.AdminReports,
              isUserActive,
            }),
          },
          type: customLink,
        },
        {
          key: `4-${logId}`,
          value: convertDecimal(pharmacyHourlyRate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `5-${logId}`,
          value: {
            label: pharmacistName,
            handlePress: goToUserProfile?.({
              personnelId: pharmacistId,
              userType: TypeOfUser.pharmacist,
              fromScreen: OtherScreens.AdminReports,
              isUserActive: isPharmacistActive,
            }),
          },
          type: customLink,
        },
        {
          key: `6-${logId}`,
          value: convertDecimal(pharmacistHourlyRate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `7-${logId}`,
          value: {
            label,
            style,
          },
          type: chip,
        },
        {
          key: `8-${logId}`,
          value: actionDateTime,
          type: text,
        },
        {
          key: `9-${logId}`,
          value: notes,
          type: text,
        },
      ],
    };
  });
