import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import {
  adminActions,
  adminReports,
  successSelector,
} from '@pharmaplan/common';

import EmptyIncompatibleMatches from '../IncompatibleMatches/EmptyIncompatibleMatches';
import useAdminReports from '../../../hooks/AdminReports/useAdminReports';
import AdminReportsHeader from './AdminReportsHeader/AdminReportsHeader';
import { deserializeISODate } from '../../../helpers/Functions';
import { useAppSelector } from '../../../hooks/useAppSelector';
import DynamicTable from '../../DynamicTable';
import strings from '../../../localization';

const AdminReports = () => {
  const { state } = useLocation();
  const { start, end } = state ?? {};

  const loadSuccess = useAppSelector((st) =>
    successSelector([adminActions.getAdminReports], st));
  const reports = useAppSelector(adminReports);
  const { data } = reports ?? {};

  const {
    table,
    page,
    totalCount,
    handlePagination,
    handleReset,
    formik,
    selectAll,
    selectAllFunc,
    deleteWorkshifts,
    selectableData,
  } = useAdminReports();

  const noData = data.length === 0;
  const afterSearchNoData = totalCount === 0 && noData;
  const emptyMsg = afterSearchNoData
    ? strings.noDataAvailable
    : strings.pleaseEnterStartAndEndDate;

  useEffect(() => {
    if (start || end) {
      formik.setFieldValue('startDate', deserializeISODate(start));
      formik.setFieldValue('endDate', deserializeISODate(end));
      formik.handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      totalCount={totalCount}
      page={page}
      handlePagination={handlePagination}
      customHeader={(
        <AdminReportsHeader
          selectAll={selectAll}
          handleSelectAll={selectAllFunc}
          formik={formik}
          handleDelete={deleteWorkshifts}
          handleReset={handleReset}
          selectableData={selectableData}
        />
      )}
      showHeader={false}
      emptyContainerComponent={
        noData ? <EmptyIncompatibleMatches message={emptyMsg} /> : null
      }
    />
  );
};

export default AdminReports;
