import React, { FC } from 'react';

import { Box } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import styles from './styles';

interface IWatchlisted {
  inCalendar?: boolean;
}

const Watchlisted: FC<IWatchlisted> = ({ inCalendar = false }) =>
  (
    <Box sx={styles.container} component="div">
      <BookmarkIcon sx={inCalendar ? styles.calendarIcon : styles.icon} />
    </Box>
  );

export default Watchlisted;
