import {
  calendarDate,
  calendarFilter,
  getPharmacistCalendar,
  getPharmacistMap,
  getPharmacistReports,
  getPharmacyCalendar,
  getPharmacyReports,
  mapDate,
  pharmacistActions,
  pharmacyActions,
  ReportRequestTypes,
  reset,
  resetStatus,
  successOrSelector,
  TypeOfUser,
} from '@pharmaplan/common';
import { useEffect } from 'react';
import {
  IInitialValues,
  IPharmacyInitialValues,
} from '../components/Reports/Reports';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import useUser from './useUser';
import { resetDrawer } from '../actions/drawerActions';
import { parseMapDate } from '../helpers/Functions';

interface IRefresh {
  page: number;
  type: ReportRequestTypes;
  initialValues: IPharmacyInitialValues & IInitialValues;
}

const useRefresh = (params?: IRefresh) => {
  const dispatch = useAppDispatch();

  const { userType } = useUser();

  const mDate = useAppSelector(mapDate);
  const cDate = useAppSelector(calendarDate);
  const filter = useAppSelector(calendarFilter);
  const selfServiceActions = [
    pharmacyActions.cancelBookings,
    pharmacistActions.selfCancelBooking,
    pharmacyActions.approveRequest,
    pharmacyActions.ignoreRequest,
  ];

  const calendarUpdateActions = [
    pharmacistActions.cancelBooking,
    pharmacistActions.cancelRequestedBooking,
    pharmacistActions.createAvailability,
    pharmacistActions.deleteAvailabilities,
    pharmacistActions.updateAvailability,
    pharmacistActions.requestBooking,
    pharmacistActions.vacations,
    pharmacistActions.updateVacations,
    pharmacistActions.deleteVacations,
    pharmacyActions.deleteWorkshift,
    pharmacyActions.updateWorkshift,
    pharmacyActions.createWorkshift,
    pharmacyActions.cancelRequestedBooking,
    ...selfServiceActions,
  ];

  const isCalendarActionSuccess = useAppSelector((state) =>
    successOrSelector(calendarUpdateActions, state));

  const refreshCalendar = () => {
    dispatch(
      userType === TypeOfUser.pharmacist
        ? getPharmacistCalendar({ ...filter, ...cDate })
        : getPharmacyCalendar(cDate),
    );
  };
  const refreshMap = () => {
    dispatch(getPharmacistMap({ ...filter, ...parseMapDate(mDate) }));
  };

  const refreshReport = () => {
    if (params) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        userType === TypeOfUser.pharmacist
          ? getPharmacistReports({
            page: params?.page,
            type: params?.type ?? ReportRequestTypes.booked,
            ...params.initialValues,
          })
          : getPharmacyReports({
            page: params?.page,
            type: params?.type ?? ReportRequestTypes.booked,
            ...params.initialValues,
          }),
      );
    }
  };

  useEffect(() => {
    if (isCalendarActionSuccess) {
      refreshCalendar();
      refreshReport();
      dispatch(reset());
      dispatch(resetStatus(calendarUpdateActions));
      dispatch(resetDrawer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarActionSuccess, params]);

  return {
    refreshMap,
    refreshCalendar,
    refreshReport,
  };
};

export default useRefresh;
