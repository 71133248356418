import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { adminDailyStats, getDailyStats } from '@pharmaplan/common';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';

import { ReactComponent as BriefcaseIcon } from '../../../../assets/svg/briefcaseIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/svg/calendarIcon.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/svg/cancelIcon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/editIcon.svg';
import { ReactComponent as PharmacyRegistrationIcon } from '../../../../assets/svg/pharmacyRegistrationIcon.svg';
import { ReactComponent as PharmacistRegistrationIcon } from '../../../../assets/svg/pharmacistRegistrationIcon.svg';

import styles from './styles';
import { Constants } from '../../../../helpers/Constants';

const {
  pendingActivationListPharmacist,
  pendingActivationListPharmacy,
  postedWorkshiftReport,
  requestedReport,
  bookingReport,
  pendingCancellation,
} = Constants.paths.admin;

const StatisticBlock = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const stats = useAppSelector(adminDailyStats);
  const {
    pharmacistCount,
    pharmacyCount,
    postingCount,
    bookingRequestCount,
    bookingsCount,
    cancellationRequestCount,
  } = stats ?? {};

  const config = [
    {
      key: 1,
      data: pharmacyCount,
      label: strings.pharmacyRegistrations,
      Icon: PharmacyRegistrationIcon,
      navigationPath: pendingActivationListPharmacy,
    },
    {
      key: 2,
      data: pharmacistCount,
      label: strings.pharmacistRegistrations,
      Icon: PharmacistRegistrationIcon,
      navigationPath: pendingActivationListPharmacist,
    },
    {
      key: 3,
      data: bookingsCount,
      label: strings.bookings,
      Icon: CalendarIcon,
      navigationPath: bookingReport,
    },
    {
      key: 4,
      data: postingCount,
      label: strings.workshiftPostings,
      Icon: BriefcaseIcon,
      navigationPath: postedWorkshiftReport,
    },
    {
      key: 5,
      data: bookingRequestCount,
      label: strings.bookingRequests,
      Icon: EditIcon,
      navigationPath: requestedReport,
    },
    {
      key: 6,
      data: cancellationRequestCount,
      label: strings.cancellationRequests,
      Icon: CancelIcon,
      navigationPath: pendingCancellation,
    },
  ];

  useEffect(() => {
    dispatch(getDailyStats());
  }, []);

  const handleClick = (path: string) =>
    () => {
      navigate(path);
    };

  return (
    <Box sx={styles.mainContainer}>
      {config.map((item) => {
        const { key, data, label, Icon, navigationPath } = item;
        return (
          <Box
            key={key}
            sx={styles.container}
            onClick={handleClick(navigationPath)}
          >
            <Typography sx={styles.label}>{label}</Typography>
            <Box sx={styles.subContainer}>
              <Typography sx={styles.data}>{data}</Typography>
              <Box sx={styles.icon}>
                <Icon />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default StatisticBlock;
