import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import {
  AdminLegendVariant,
  PharmacistLegendVariant,
  activeDate,
} from '@pharmaplan/common';
import { useNavigate } from 'react-router-dom';
import { Constants } from '../../../helpers/Constants';
import strings from '../../../localization';
import classes from './style';
import { getVisibleDatesMonth } from '../../../helpers/Functions';
import { useAppSelector } from '../../../hooks/useAppSelector';

interface IPharmacistLegend {
  variant: `${PharmacistLegendVariant}` | `${AdminLegendVariant}`;
  totalHrs: number;
  totalCount: number;
  customClass?: Record<string, unknown>;
}

const { requested, availability, workshift, booked, vacation } = PharmacistLegendVariant;
const { total, posted } = AdminLegendVariant;

const PharmacistLegend = ({
  variant,
  totalHrs,
  totalCount,
  customClass,
}: IPharmacistLegend) => {
  const navigate = useNavigate();

  const currentDate = useAppSelector(activeDate);
  const { start, end } = getVisibleDatesMonth(currentDate);

  const titleConfig = {
    [requested]: strings.requestedWorkshifts,
    [availability]: strings.availabilitesReports,
    [booked]: strings.bookingReport,
    [workshift]: strings.availableWorkshifts,
  };

  const legendTitle = {
    [requested]: strings.requested,
    [availability]: strings.availabilities,
    [booked]: strings.booked,
    [workshift]: strings.workshifts,
    [vacation]: strings.vacations,
    [total]: strings.total,
    [posted]: strings.posted,
  };

  const isVacation = variant === PharmacistLegendVariant.vacation;

  const onClick = () => {
    if (isVacation) {
      return;
    }

    navigate(Constants.paths.reports, {
      state: {
        type: variant,
        title: titleConfig[variant as keyof typeof titleConfig],
        start,
        end,
      },
    });
  };

  return (
    <Grid
      wrap="nowrap"
      variant="text"
      component={Button}
      container
      sx={[
        classes.legendContainerBg(variant),
        classes.container(variant),
        customClass as SystemStyleObject,
      ]}
      onClick={onClick}
    >
      <Grid
        item
        xs={12}
        md={8}
        sx={[classes.startContainer, classes.columnContainer]}
      >
        <Box component="div" sx={classes.startContainer}>
          <Typography noWrap sx={classes.legendTitle}>
            {legendTitle[variant]}
          </Typography>
        </Box>
        <Box
          component="div"
          sx={[
            classes.legendTotalHrs,
            classes.totalHrsCountColor(variant),
            classes.startContainer,
          ]}
        >
          {strings.formatString(strings.hoursWorked, Math.trunc(totalHrs))}
        </Box>
      </Grid>
      <Grid item xs={10} md={2} sx={classes.totalCountContainer}>
        <Box
          sx={[classes.legendTotalCount, classes.totalHrsCountColor(variant)]}
          component="div"
        >
          {totalCount}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PharmacistLegend;
