import React, { useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  getPharmacyAdminLists,
  IPharmacyAdminListApiParams,
  pharmacyAdminList,
} from '@pharmaplan/common';

import usePharmacyAdministratorActions from '../../../hooks/Admin/PharmacyAdministrators/usePharmacyAdministratorActions';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useUserProfileLink from '../../../hooks/Admin/useUserProfileLink';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { IDynamicTableObject } from '../../DynamicTable/types';
import DynamicTable from '../../DynamicTable';
import strings from '../../../localization';

import { pharmacyAdminHeaders, pharmacyAdminRows } from './helper';
import PharmacyAdminHeader from './PharmacyAdminHeader';

const initialValues = {
  adminText: '',
  pharmacyText: '',
};

const PharmacyAdministrators = () => {
  const dispatch = useAppDispatch();

  const list = useAppSelector(pharmacyAdminList);
  const { can } = useAdminPermissions();
  const { goToUserProfile } = useUserProfileLink();

  const apiCall = (params?: IPharmacyAdminListApiParams) => {
    dispatch(getPharmacyAdminLists(params ?? initialValues));
  };

  const handleSubmit = (values: FormikValues) => {
    apiCall(values as IPharmacyAdminListApiParams);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const { editAction, deleteAction, loadSuccess } = usePharmacyAdministratorActions(formik.values);

  const table: IDynamicTableObject = {
    title: strings.pharmacyAdministrators,
    headers: pharmacyAdminHeaders(can),
    rows: pharmacyAdminRows({
      data: list,
      deleteAction,
      editAction,
      goToUserProfile,
      can,
    }),
    headerBar: [],
  };

  useEffect(() => {
    apiCall();
  }, []);

  const handleReset = () => {
    formik.resetForm();
    apiCall();
  };

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      hidePagination
      showHeader={false}
      customHeader={
        <PharmacyAdminHeader formik={formik} handleReset={handleReset} />
      }
    />
  );
};

export default PharmacyAdministrators;
