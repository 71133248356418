import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { adminActions, resetStatus } from '@pharmaplan/common';

import useBroadcastTabs from '../../../hooks/Admin/BroadcastList/useBroadcastTabs';
import { showSuccess } from '../Profile/Pharmacist/PharmacistViewProfile/helper';
import { BroadcastTabsType, Constants } from '../../../helpers/Constants';
import { IDynamicTableObject } from '../../DynamicTable/types';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { parseUrlType } from '../../../helpers/Functions';
import DynamicTable from '../../DynamicTable';
import strings from '../../../localization';

import BroadcastListHeader from './BroadcastListHeader';

const { notifications, groups } = BroadcastTabsType;
const { deleteBroadcastGroup, deleteBroadcastNotification } = adminActions;
const { broadcastListGroups, broadcastListNotification } = Constants.paths.admin;

const BroadcastList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [prefix, selectedTabs] = parseUrlType(
    window.location.pathname,
    [groups, notifications],
    4,
    notifications,
  );

  const handleTabClick = (tab: BroadcastTabsType) => {
    window.history.pushState({}, '', `${prefix}/${tab}`);
  };

  const {
    rows,
    headers,
    success,
    initialValues,
    onSubmit,
    list,
    baseApi,
    deleteSuccess,
  } = useBroadcastTabs(selectedTabs);

  const { totalCount } = list;

  const table: IDynamicTableObject = {
    headerBar: [],
    headers,
    rows,
  };

  useEffect(() => {
    baseApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabs]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) =>
      onSubmit(values),
    enableReinitialize: true,
  });

  const handleReset = () => {
    setPage(1);
    formik.resetForm();
    baseApi();
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    setPage(selectedPage + 1);
    onSubmit(formik.values, selectedPage);
  };

  useEffect(() => {
    if (deleteSuccess) {
      onSubmit(formik.values, page);
      showSuccess(dispatch, strings.deletedSuccessfully);
      dispatch(
        resetStatus([deleteBroadcastGroup, deleteBroadcastNotification]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  const customTabClick = (selectedTab: BroadcastTabsType) => {
    if (selectedTab === notifications) {
      navigate(broadcastListNotification);
    } else {
      navigate(broadcastListGroups);
    }

    handleTabClick(selectedTab);
  };

  return (
    <DynamicTable
      customHeader={(
        <BroadcastListHeader
          handleReset={handleReset}
          formik={formik}
          selectedTabs={selectedTabs}
          handleTabClick={customTabClick}
        />
      )}
      handlePagination={handlePagination}
      loadSuccess={success}
      table={table}
      showHeader={false}
      page={page}
      totalCount={totalCount}
    />
  );
};

export default BroadcastList;
