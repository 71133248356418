import {
  getLanguages,
  logos,
  IFilterSoftware,
  IPharmacistCapacities,
  IContactPreferences,
  selectPharmacistPitures,
  IPublishedSoftwareList,
} from '@pharmaplan/common';
import { useAppSelector } from './useAppSelector';
import DefaultBanner from '../assets/svg/defaultBanner.svg';

const useGetItems = () => {
  const allLogos = useAppSelector(logos);
  const allLanguages = useAppSelector(getLanguages);
  const pharmacistPictures = useAppSelector(selectPharmacistPitures);

  const getSoftwareName = (
    data: IFilterSoftware[] | IPublishedSoftwareList[],
    softwareId: string,
  ) =>
    data.find((item) =>
      item.softwareId === softwareId)?.name ?? '';

  const getImage = (logoId: string) =>
    allLogos.find((item) =>
      item.logoId === logoId)?.image ?? DefaultBanner;

  const getLanguageName = (languageId: string) =>
    allLanguages.find((item) =>
      item.languageId === languageId)?.name ?? '';

  const getLanguageCode = (languageId: string) =>
    allLanguages.find((item) =>
      item.languageId === languageId)?.code ?? '';

  const getPharmacistCapacities = (
    data: IPharmacistCapacities[],
    pharmacistCapacityId: string,
  ) =>
    data?.find((item) =>
      item?.pharmacistCapacityId === pharmacistCapacityId)
      ?.description ?? '';

  const getSelectedContactPreferences = (
    data: Array<IContactPreferences>,
    selectedContactPreferences: Array<string>,
  ) =>
    selectedContactPreferences
      ?.map((id) =>
        data?.find((item) =>
          id === item.contactPreferenceId)?.name)
      .join(', ');

  const getPharmacistImage = (pharmacistId: string) =>
    pharmacistPictures.find((item) =>
      item.pharmacistId === pharmacistId)
      ?.image;

  const getPharmacyChain = (pharmacyChainId: string) =>
    allLogos.find((item) =>
      item.pharmacyChainId === pharmacyChainId)?.image
    ?? DefaultBanner;

  return {
    getImage,
    getLanguageName,
    getSoftwareName,
    getPharmacistCapacities,
    getSelectedContactPreferences,
    getPharmacistImage,
    getLanguageCode,
    getPharmacyChain,
  };
};

export default useGetItems;
