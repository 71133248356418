import React, { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import 'react-calendar/dist/Calendar.css';

import {
  activeDate,
  setActiveWebView,
  CalendarStateKey,
  TypeOfUser,
  getActiveWebView,
  successOrSelector,
  pharmacistActions,
  pharmacyActions,
  reset,
  getNearbyProps,
  ReportRequestTypes,
} from '@pharmaplan/common';
import { ReactComponent as FilledFilter } from '@pharmaplan/common/assets/icons/searchIconGreen.svg';
import { ReactComponent as UnfilledFilter } from '@pharmaplan/common/assets/icons/searchIcon.svg';
import { ReactComponent as CloseFilter } from '@pharmaplan/common/assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/webPlus.svg';

import CachedIcon from '@mui/icons-material/Cached';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/deleteIcon.svg';

import CustomDropdown from '../../common/CustomDropdown';
import CustomButton from '../../common/CustomButton';
import CustomCheckbox from '../../common/CustomCheckbox';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useAdmin from '../../../hooks/useAdmin';
import useUser from '../../../hooks/useUser';
import useNearbyPharmacies from '../../../hooks/Toolbar/useNearbyPharmacies';
import useAdminToolbarReset from '../../../hooks/Admin/useAdminToolbarReset';
import useAddEvent from '../../../hooks/useAddEvent';
import useMultipleSave from '../../../hooks/useMultipleSave';
import useAdminContinueCreation from '../../../hooks/Admin/useAdminContinueCreation';
import useMultipleDelete from '../../../hooks/useMultipleDelete';
import {
  setMultipleAddEvents,
  setTempEvents,
  setTempVacations,
} from '../../../reducers/mainCalendarReducer';
import { resetDialog } from '../../../reducers/dialogReducer';
import { getMultipleEventAdd } from '../../../selectors/calendarSelector';
import ThemeConstants from '../../../theme/ThemeConstants';
import { MonthWeek } from '../../../helpers/Functions';
import { Constants, MomentFormatOptions } from '../../../helpers/Constants';
import strings from '../../../localization';

import DashboardFilter from './DashboardFilter';
import DayCalendar from './DayCalendar';
import YearCalendar from './YearCalendar';
import NavigateButtons from './NavigateButtons';
import CalendarMapToggle from './CalendarMapToggle';
import AdminDashboardFilter from './AdminDashboardFilter';
import classes from './style';
import Watchlisted from '../../common/Watchlist/Watchlisted';

export interface IOnNavigate {
  setDate: (date: Moment) => void;
}

interface IToolbarProps {
  title: string | Array<string>;
}

const Toolbar = ({ title }: IToolbarProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userType } = useUser();
  const navigate = useNavigate();

  const { isAdmin } = useAdmin();
  const { showConfirmModal } = useAdminContinueCreation();
  const {
    deleteSelectedAvailabilities,
    getDeletedAvailabilities,
    getDeletedVacations,
    handleDeleteReset,
  } = useMultipleDelete();

  const {
    isAddEvents,
    events: addEvent,
    isVacations,
    typeofAvailability,
  } = useAddEvent();
  const { handleSave } = useMultipleSave();

  const dayCalendarRef = useRef<{ reset:() => void }>();
  const ref = useRef<{ reset:() => void; clear: () => void }>();
  const nearbyProps = useAppSelector(getNearbyProps);

  const [filterState, setFilterState] = useState({
    submitted: false,
    showFilter: false,
  });
  const { checkForLocation } = useNearbyPharmacies();
  const date = useAppSelector(activeDate);
  const activeView = useAppSelector(getActiveWebView);
  const multipleEventAdd = useAppSelector(getMultipleEventAdd);

  useAdminToolbarReset({ filterState, setFilterState });

  const success = useAppSelector((st) =>
    successOrSelector(
      [
        pharmacistActions.createAvailability,
        pharmacyActions.createWorkshift,
        pharmacistActions.vacations,
      ],
      st,
    ));

  const {
    toolbarContainer,
    toolbarHeadingContainer,
    filterCont,
    toggleViewContainer,
    navigationToggle,
    navigationToggleLabel,
    navigateDateContainer,
    calendarHeading,
    navigateDate,
    searchIcon,
    multipleSelectButtons,
    resetButton,
    dropdownCustomHeight,
    clearButton,
  } = classes;

  const { submitted, showFilter } = filterState;
  const { pathname } = location;
  const {
    paths: { mapDashboard, admin: adminPaths },
  } = Constants;

  const { adminMapDashboard } = adminPaths;

  const { pharmacist } = TypeOfUser;
  const { shortMonthName, fullYear } = MomentFormatOptions;

  const isPharmacist = userType === pharmacist;
  const pharmacistToggle = pathname === mapDashboard;
  const adminMapToggle = pathname === adminMapDashboard;
  const mapToggle = isAdmin ? adminMapToggle : pharmacistToggle;

  const hideMonthWeek = !mapToggle;

  const showMap = isPharmacist || isAdmin;

  const shouldShowSelectButton = multipleEventAdd && isAddEvents;

  const formattedDate = moment(date).format(`${shortMonthName}, ${fullYear}`);

  const disableSave = addEvent.filter(
    (item: { type: string }) =>
      item.type === typeofAvailability,
  ).length === 0;

  const disableDelete = getDeletedAvailabilities().length === 0
    && getDeletedVacations().length === 0;
  const disableReset = disableSave && isAdmin && disableDelete;

  const formik = useFormik({
    initialValues: {
      calendarState: activeView.toLowerCase(),
    },
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const handleReset = () => {
    if (isVacations) {
      dispatch(setTempVacations([]));
    } else {
      dispatch(setTempEvents([]));
    }
    handleDeleteReset();
  };

  const handleFilterState = () => {
    setFilterState({
      ...filterState,
      showFilter: !showFilter,
    });
  };

  const filterStates = () => {
    switch (true) {
      case !submitted && !showFilter:
        return (
          <IconButton disableRipple onClick={handleFilterState}>
            <UnfilledFilter id="filter" />
          </IconButton>
        );
      case !submitted && showFilter:
        return (
          <IconButton
            disableRipple
            onClick={() => {
              handleFilterState();
              ref.current?.clear();
            }}
          >
            <CloseFilter fill={ThemeConstants.grey[800]} />
          </IconButton>
        );
      case submitted:
        return (
          <IconButton disableRipple onClick={handleFilterState}>
            <FilledFilter />
          </IconButton>
        );
      default:
        return null;
    }
  };

  const {
    values: { calendarState },
  } = formik ?? {};

  useEffect(() => {
    if (success && multipleEventAdd) {
      handleReset();
      dispatch(resetDialog());
      dispatch(reset());
    }

    return () => {
      dispatch(setMultipleAddEvents(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(setActiveWebView(calendarState as CalendarStateKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarState, date]);

  return (
    <Box sx={toolbarContainer}>
      <Box sx={toolbarHeadingContainer}>
        <Typography variant="h5" sx={calendarHeading}>
          {title}
        </Typography>

        {!adminMapToggle && showMap && !multipleEventAdd && (
          <Box sx={filterCont}>
            {submitted && (
              <CustomButton
                customClass={clearButton}
                label={strings.clear}
                variant="outlined"
                onClick={() => {
                  ref?.current?.reset();
                  dayCalendarRef.current?.reset();
                }}
              />
            )}
            {isPharmacist && (
              <IconButton
                disableRipple
                onClick={() =>
                  navigate(Constants.paths.reports, {
                    state: {
                      type: ReportRequestTypes.watchlist,
                      title: strings.watchlistReport,
                    },
                  })}
              >
                <Watchlisted />
              </IconButton>
            )}

            <Box sx={searchIcon}>{filterStates()}</Box>
          </Box>
        )}
      </Box>

      {isPharmacist && (
        <DashboardFilter
          filterState={filterState}
          setFilterState={setFilterState}
          ref={ref}
        />
      )}

      {!mapToggle && isAdmin && filterState.showFilter && (
        <AdminDashboardFilter
          filterState={filterState}
          setFilterState={setFilterState}
          ref={ref}
        />
      )}

      <Box sx={navigateDateContainer}>
        <Box sx={navigateDate}>
          <Box id="calendarViewDropdown" component="div" sx={navigationToggle}>
            <Typography variant="subtitle1" sx={navigationToggleLabel}>
              {formattedDate}
            </Typography>
            {mapToggle ? (
              <DayCalendar ref={dayCalendarRef} setFiltered={setFilterState} />
            ) : (
              <YearCalendar />
            )}
          </Box>
          <NavigateButtons />
        </Box>

        <Box component="div" sx={toggleViewContainer}>
          {shouldShowSelectButton && (
            <Box sx={multipleSelectButtons}>
              <CustomButton
                startIcon={<CachedIcon />}
                customClass={resetButton}
                onClick={handleReset}
                disabled={disableReset}
                variant={disableReset ? 'contained' : 'outlined'}
                label={strings.reset}
                size="small"
              />

              <CustomButton
                customClass={resetButton}
                startIcon={<PlusIcon />}
                onClick={isAdmin ? showConfirmModal(handleSave) : handleSave}
                disabled={disableSave}
                label={strings.save}
                size="small"
              />

              {isAdmin && (
                <CustomButton
                  size="small"
                  customClass={resetButton}
                  startIcon={
                    <DeleteIcon stroke={ThemeConstants.secondary.main} />
                  }
                  onClick={deleteSelectedAvailabilities}
                  disabled={disableDelete}
                  label={strings.delete}
                  color="error"
                />
              )}
            </Box>
          )}

          {mapToggle && (
            <CustomCheckbox
              label={strings.pharmaciesNearby}
              name="toggle"
              value={nearbyProps.showNearBy}
              labelClass={classes.checkboxLabel}
              handleChange={checkForLocation}
              id="toggle"
            />
          )}

          {showMap && <CalendarMapToggle disabled={isAddEvents} />}
          {hideMonthWeek && (
            <CustomDropdown
              id="monthWeek"
              name="calendarState"
              formik={formik}
              disabled={isAddEvents}
              customClass={dropdownCustomHeight}
              menuItems={MonthWeek()}
              placeholder={Constants.null}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Toolbar;
