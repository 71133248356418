import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminBroadcastRecipientList,
  BroadcastNotificationType,
} from '@pharmaplan/common';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';

import styles from './styles';

const { message, email, pushNotification } = BroadcastNotificationType;

interface IRecipientList {
  label: string;
  type: BroadcastNotificationType;
}

const RecipientList: FC<IRecipientList> = ({ label, type }) => {
  const recipientList = useAppSelector(adminBroadcastRecipientList);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
      <Grid sx={styles.recipientsContainer} container>
        {recipientList.map((item) => {
          const {
            userId,
            userName,
            mobile,
            communicationEmail,
            email: userEmail,
          } = item ?? {};

          const primarySecondaryConfig = {
            [message]: `${userName} (${mobile})`,
            [email]: communicationEmail
              ? `${userName} (${userEmail}, ${communicationEmail})`
              : `${userName} (${userEmail})`,
            [pushNotification]: '',
          };

          return (
            <Grid key={userId} item>
              <Typography sx={styles.textChip}>
                {primarySecondaryConfig[type]}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default RecipientList;
