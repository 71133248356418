import React, { FC, useEffect } from 'react';

import {
  BookingState,
  counterOffer,
  ICalendarEventType,
  pharmacistActions,
  pharmacistEvent,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';

import CustomButton from '../../common/CustomButton';
import { setButtons, setPagination } from '../../../actions/drawerActions';
import { selectFooterPaginationCurrent } from '../../../selectors/drawerSelector';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useServiceWorkshiftDetailsEvent from '../../../hooks/serviceWorkshiftDetails/useServiceWorkshiftDetailsEvent';
import strings from '../../../localization';
import { Constants } from '../../../helpers/Constants';

import ContactDetails from '../ContactDetails';
import PharmacyOperation from '../PharmacyOperation';
import ServiceNote from '../ServiceNotes';
import PharmacyCard from '../PharmacyCard';

import ConfirmBox from './ConfirmBox';
import { showConfirmBox } from './helper';
import classes from './styles';

export interface IServiceWorkshiftDetails {
  eventIds: Array<string>;
  eventType: ICalendarEventType;
}

const { None } = BookingState;
const { counterOfferBtn, counterOfferContainer } = classes;

const {
  variant: { text },
} = Constants;

const ServiceWorkshiftDetails: FC<IServiceWorkshiftDetails> = ({
  eventIds,
  eventType,
}) => {
  const dispatch = useAppDispatch();
  const serviceType = ServiceHandler.getService();

  const {
    serviceConfig,
    goToCounterOffer,
    buttonConfig,
    isBooked,
    isSelfWorkshift,
    isCounterOffer,
    isWorkshift,
  } = useServiceWorkshiftDetailsEvent({ eventIds, eventType });

  const event = useAppSelector(pharmacistEvent);
  const current = useAppSelector(selectFooterPaginationCurrent);
  const counterOfferEvent = useAppSelector(counterOffer);

  const { notes, bookingState } = event ?? {};
  const { notes: counterNotes } = counterOfferEvent ?? {};

  const displayNote = isCounterOffer ? counterNotes : notes;

  const BillDetails = serviceConfig[serviceType].billDetails;
  const { primaryButton, secondaryButton } = buttonConfig[eventType as keyof typeof buttonConfig];

  const successAction = isWorkshift
    ? pharmacistActions.getPharmacistWorkshiftDetails
    : pharmacistActions.getEvent;

  const handleButtonClick = () => {
    goToCounterOffer(eventIds);
  };

  useEffect(() => {
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          variant: 'contained',
          ...primaryButton,
        },
        secondaryButton: {
          variant: 'text',
          ...secondaryButton,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonConfig, eventType]);

  useEffect(() => {
    dispatch(
      setPagination({
        show: true,
        total: eventIds.length,
        current,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventIds.length]);

  return (
    <>
      {showConfirmBox(bookingState ?? None, eventType) && <ConfirmBox />}

      <PharmacyCard
        successAction={successAction}
        event={event}
        type={eventType}
      />

      {BillDetails}

      {isSelfWorkshift && (
        <CustomButton
          variant={text}
          label={strings.submitCounterOffer}
          onClick={handleButtonClick}
          customButtonStyle={counterOfferBtn}
          customClass={counterOfferContainer}
        />
      )}

      {isBooked && <ContactDetails successAction={successAction} />}
      <PharmacyOperation successAction={successAction} event={event} />
      {displayNote && <ServiceNote notes={displayNote} />}
    </>
  );
};

export default ServiceWorkshiftDetails;
