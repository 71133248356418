/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useRef } from 'react';

import { Grid } from '@mui/material';

import {
  axisClasses,
  BarPlot,
  ChartsAxisHighlight,
  ChartsGrid,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  PieChart,
  ResponsiveChartContainer,
} from '@mui/x-charts';

import { ChartTypes, IChartsBuilder } from './types';
import CustomChartLegend from './CustomChartLegend';
import ChartBorderLayout from './ChartBorderLayout';

const ChartBuilder: FC<IChartsBuilder> = ({ config }) => {
  const legendProps = useRef<any>(null);

  return (
    <>
      {config.map((item) => {
        const {
          key,
          type,
          chartSettings,
          sx,
          xs = 6,
          useDoubleYAxis,
          userTypeTitle,
          margin,
          rightLabelXTranslate,
        } = item;

        const rightMargin = userTypeTitle ? { right: 20 } : {};

        switch (type) {
          case ChartTypes.bar:
          case ChartTypes.composite:
            return (
              <Grid key={key} xs={xs} item>
                <ChartBorderLayout title={userTypeTitle}>
                  <ResponsiveChartContainer
                    margin={{ top: 20, ...rightMargin, ...margin }}
                    {...chartSettings}
                    sx={{
                      [`& .${axisClasses.right} .${axisClasses.label}`]: {
                        transform: `translateX(${rightLabelXTranslate})`,
                      },
                    }}
                  >
                    <ChartsGrid horizontal />
                    <BarPlot borderRadius={10} />
                    <LinePlot />
                    <MarkPlot />
                    <ChartsXAxis
                      slotProps={{ axisTickLabel: { fontSize: 15 } }}
                    />
                    <ChartsYAxis axisId="leftAxis" />
                    {useDoubleYAxis && (
                      <ChartsYAxis axisId="rightAxis" position="right" />
                    )}
                    <ChartsLegend
                      slots={{
                        legend: (props) => {
                          legendProps.current = {
                            ...legendProps.current,
                            [key]: props.seriesToDisplay,
                          };
                          return <></>;
                        },
                      }}
                    />
                    <ChartsTooltip trigger="item" />
                    <ChartsAxisHighlight x="band" />
                  </ResponsiveChartContainer>
                  <CustomChartLegend
                    containerXs={xs !== 6}
                    mapKey={key}
                    legendProps={legendProps.current}
                  />
                </ChartBorderLayout>
              </Grid>
            );
          case ChartTypes.pie:
            return (
              <Grid key={key} xs={xs} item>
                <ChartBorderLayout isPie>
                  <PieChart
                    margin={{ top: 25, right: 0, bottom: 30 }}
                    sx={sx}
                    {...chartSettings}
                    slots={{
                      legend: (props) => {
                        legendProps.current = {
                          ...legendProps.current,
                          [key]: props.seriesToDisplay,
                        };
                        return <></>;
                      },
                    }}
                  />
                  <CustomChartLegend
                    containerXs={xs !== 6}
                    mapKey={key}
                    legendProps={legendProps.current}
                    chartType={ChartTypes.pie}
                  />
                </ChartBorderLayout>
              </Grid>
            );

          default:
            return <></>;
        }
      })}
    </>
  );
};
export default ChartBuilder;
