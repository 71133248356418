import { DateTime } from 'luxon';
import { SlotInfo } from 'react-big-calendar';

import EventCreator, {
  IBaseCalendarEvent,
} from '../helpers/Calendar/EventCreator';
import { exists, getId, isPast } from '../helpers/Functions';
import {
  setTempEvents,
  setTempVacations,
} from '../reducers/mainCalendarReducer';
import { getMultipleEventAdd } from '../selectors/calendarSelector';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import useAddEvent from './useAddEvent';

const useMultipleEvents = () => {
  const dispatch = useAppDispatch();
  const { typeofAvailability, isVacations, events } = useAddEvent();
  const { createBaseCalendarEvent } = EventCreator();

  const isMultipleAdd = useAppSelector(getMultipleEventAdd);

  const tempArray = [...events];

  const prepareEvents = (eventDate: Date | string) => {
    if (exists(tempArray, eventDate as string)) {
      return false;
    }

    const startDateParsed = DateTime.fromJSDate(eventDate as Date)
      .set({ hour: 8 })
      .toISO({ includeOffset: false });
    const endDateParsed = DateTime.fromJSDate(eventDate as Date)
      .set({ hour: 22 })
      .toISO({ includeOffset: false });

    const prepEvent = createBaseCalendarEvent({
      id: getId(eventDate as string),
      selectedDate: startDateParsed,
      startDate: startDateParsed,
      endDate: endDateParsed,
      type: typeofAvailability,
      status: 0,
      count: 1,
      end: endDateParsed,
      start: startDateParsed,
    });

    return prepEvent;
  };

  const handleEventDispatch = (res: (false | IBaseCalendarEvent)[]) => {
    if (isVacations) {
      dispatch(setTempVacations(res));
    } else {
      dispatch(setTempEvents(res));
    }
  };

  const createNewEvent = (event: SlotInfo) => {
    const res = event.slots.reduce(
      (acc: Array<IBaseCalendarEvent | false>, curr: string | Date) => {
        if (isPast(curr as Date)) {
          return acc;
        }
        if (!prepareEvents(curr)) {
          return (acc as Array<IBaseCalendarEvent>).filter(
            (item) =>
              item.id !== getId(curr as string),
          );
        }
        acc.push(prepareEvents(curr));
        return acc;
      },
      tempArray,
    );
    handleEventDispatch(res);
  };

  return {
    createNewEvent,
    multipleCalendarEvents: isMultipleAdd ? events : [],
  };
};

export default useMultipleEvents;
