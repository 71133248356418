import ThemeConstants from './ThemeConstants';

const genericClasses = {
  bold: {
    fontWeight: 800,
  },
  gridLabel: {
    marginBottom: '5px',
  },
  paginationDiv: {
    marginBottom: '15px',
  },
  hidden: {
    display: 'none',
  },
  paddingZero: {
    padding: 0,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
  },
  actionsFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerStyles: {
    fontSize: 14,
    fontWeight: '600',
    color: 'common.grey850',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  iconMargin: {
    marginRight: '10px',
  },
  profileInputMargins: {
    margin: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  removeProfileMargin: {
    margin: 0,
  },
  inputPadding: {
    padding: '15px',
  },
  loginMargins: {},
  addAvailabilityMargin: {
    marginBottom: '1.2rem',
    marginTop: '1rem',
  },
  legendsMargin: {
    marginBottom: '0.5rem',
  },
  legendsRowMargin: {
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  fixedDropdown: {
    position: 'absolute',
  },
  borderNoneTextField: {
    '& fieldset': { border: 'none' },
  },
  checkboxContainer: {
    margin: 0,
    marginBottom: '5px',
  },
  checkboxStyle: {
    margin: 0,
    padding: 0,
    marginBottom: '4px',
    marginLeft: '7px',
  },
  inputModalTypography: {
    color: 'common.gray',
    fontSize: 14,
  },
  customFilledInput: {
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none',
        content: "''",
        position: 'relative',
        border: 'none',
      },
      '&:after': {
        borderBottom: 'none',
        border: 'none',
      },
      borderRadius: '4px',
      backgroundColor: 'common.lightBackgroundColor',
    },
    '& .MuiFilledInput-input': {
      paddingTop: '8px',
      fontSize: 14,
    },
  },
  tableScroll: {
    maxHeight: '240px',
    overflow: 'auto',
  },
  preExistingText: {
    color: 'grey.500',
    fontWeight: '500',
  },
  editedText: {
    color: 'grey.800',
  },
  toastWidth: {
    minWidth: '500px',
  },
  timeContainer: {
    display: 'flex',
    gap: '30px',
    marginBottom: '16px',
  },
  drawerTableHead: {
    fontSize: 12,
    fontWeight: 600,
  },
  filterMargin: {
    marginLeft: '32px',
  },
  error: {
    fontSize: 12,
    color: ThemeConstants.common.error,
  },
  dateContainer: {
    marginBottom: '15px',
  },
  imageName: {
    display: 'flex',
    flexWrap: 'wrap',
    wordBreak: 'break-all',
    color: 'primary.main',
    fontSize: 14,
    fontWeight: 400,
    textDecorationLine: 'underline',
    ml: 1,
  },
  button: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
};

export default genericClasses;
