import React from 'react';
import {
  AdminLegendVariant,
  BookingState,
  ICalendarEventType,
  PendingCancellationTabTypes,
  PharmacyLegendVariant,
  RecurrenceActions,
} from '@pharmaplan/common';

import {
  AdminNotesType,
  OtherScreens,
  ScreenTypes,
} from '../../helpers/Constants';
import ServiceWorkshiftDetails from '../SelfService/ServiceWorkshiftDetails';
import strings from '../../localization';
import Add from '../SelfService/Workshifts/AddWorkshift/Add';
import AddAvailability from '../Availability/AddAvailability';
import UpdateAvailability from '../Availability/UpdateAvailability';
import PharmacistProfile from '../SelfService/PharmacistProfile/PharmacistProfile';
import WorkshiftSummary from '../SelfService/WorkshiftSummary/WorkshiftSummary';
import BookingRequestsList from '../SelfService/BookingRequestsList/BookingRequestsList';
import ViewPharmacistRating from '../MyReplacements/PharmacistRating/ViewPharmacistRating/ViewPharmacistRating';
import UpdateWorkshift from '../SelfService/Workshifts/UpdateWorkshift';
import SubmitCounterOffer from '../SelfService/SubmitCounterOffer/SubmitCounterOffer';
import AvailableWorkshifts from '../Admin/AvailableWorkshifts';
import ViewDetails from '../Admin/ActiveUsers/ViewDetails';
import EditWorkshift from '../Admin/EditWorkshift';
import RequestList from '../Admin/RequestList';
import RequestedPharmacistList from '../Admin/RequestedPharmacistList';
import ViewRequestedDetails from '../Admin/RequestedPharmacistList/ViewRequestedDetails';
import ApproveBooking from '../Admin/ApproveBooking';
import MatchingAvailabilities from '../Admin/MatchingAvailabilities';
import BookingList from '../Admin/BookingList';
import ViewBookedDetails from '../Admin/BookingList/ViewBookedDetails';
import ViewAdminPharmacistRating from '../Admin/ViewAdminPharmacistRating';
import PharmacyViewProfile from '../Admin/Profile/Pharmacy/PharmacyViewProfile';
import PharmacistViewProfile from '../Admin/Profile/Pharmacist/PharmacistViewProfile/PharmacistViewProfile';
import ExpressBooking from '../Admin/ExpressBooking';
import PharmacyEditProfile from '../Admin/Profile/Pharmacy/PharmacyEditProfile/PharmacyEditProfile';
import PharmacistEditProfile from '../Admin/Profile/Pharmacist/PharmacistEditProfile/PharmacistEditProfile';
import AdminGeographicAvailability from '../Admin/Profile/AdminGeographicAvailability/AdminGeographicAvailability';
import PendingCancellationDetails from '../Admin/PendingCancellations/PendingCancellationDetails';
import { ICustomBack, IFromScreen } from '../../actions/drawerActions';
import PharmacistBookings from '../Admin/PharmacistBookings';
import PharmacyBookingDetails from '../Admin/PharmacyBookingDetails/PharmacyBookingDetails';
import ShowPharmacistAvailabilities from '../Admin/ShowPharmacistAvailabilities';
import MatchingWorkshifts from '../Admin/MatchingWorkshifts';
import AdminPharmacistHistory from '../Admin/PharmacistList/AdminPharmacistHistory';
import AdminReplacementNotes from '../Admin/PharmacistList/AdminReplacementNotes';
import ListOfPharmacies from '../Admin/PharmacistList/ListOfPharmacies';
import AdminCreateWorkshift from '../Admin/PharmacyList/AdminCreateWorkshift';
import ListOfPharmacists from '../Admin/PharmacyList/ListOfPharmacists';
import AdminPharmacyHistory from '../Admin/PharmacyList/ListOfPharmacists/AdminPharmacyHistory/AdminPharmacyHistory';
import AdminPharmacyNotes from '../Admin/PharmacyList/ListOfPharmacists/AdminPharmacyNotes/AdminPharmacyNotes';
import AdminCreateAvailability from '../Admin/PharmacistList/AdminCreateAvailability';
import AdminNotes from '../Admin/AdminNotes';
import AdminWatchlists from '../Admin/AdminWatchlists';
import BroadcastGroup from '../Admin/BroadcastList/BroadcastGroup';
import CreateVacation from '../common/CreateVacation';

export interface IScreenConfig {
  type?: ICalendarEventType;
  eventIds?: Array<string> | string;
  eventId?: string;
  date?: string;
  bookingState?: BookingState;
  workshiftId?: string;
  pharmacistId?: string;
  recType?: RecurrenceActions;
  recurrenceId?: string;
  title?: string;
  pharmacyId?: string;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  back?: ICustomBack;
  extraParameters?: any;
}

export const screenConfigs = ({
  type = PharmacyLegendVariant.posted,
  eventIds = [],
  eventId = '',
  date = '',
  bookingState = BookingState.None,
  workshiftId = '',
  pharmacistId = '',
  recType = RecurrenceActions.occurence,
  recurrenceId = '',
  pharmacyId = '',
  fromScreen,
  hasOwner,
  back,
  extraParameters,
}: IScreenConfig) => {
  const { name, enableActions, notesType, enableDelete } = extraParameters ?? {};

  return {
    [ScreenTypes.serviceWorkshiftDetails]: [
      {
        title: strings.workshiftDetails,
        content: (
          <ServiceWorkshiftDetails
            eventIds={eventIds as Array<string>}
            eventType={type}
          />
        ),
      },
      {
        title: strings.submitCounterOfferDef,
        content: (
          <SubmitCounterOffer
            eventIds={eventIds as Array<string>}
            id={eventId}
            type={type}
          />
        ),
      },
    ],
    [ScreenTypes.addWorkshift]: [
      {
        title: strings.addWorkshifts,
        content: <Add date={date} />,
      },
    ],
    [ScreenTypes.addAvailability]: [
      {
        title: strings.addAvailability,
        content: <AddAvailability date={date} />,
      },
    ],
    [ScreenTypes.updateAvailability]: [
      {
        title: strings.updateAvailability,
        content: (
          <UpdateAvailability
            id={eventIds?.[0]}
            recType={recType}
            recurrenceId={recurrenceId}
          />
        ),
      },
    ],
    [ScreenTypes.workshiftSummary]: [
      {
        title: '',
        content: (
          <WorkshiftSummary
            bookingState={bookingState}
            eventIds={eventIds as Array<string>}
            type={type}
          />
        ),
      },
      {
        title: '',
        content: (
          <BookingRequestsList
            date={date}
            eventIds={eventIds as Array<string>}
            type={type}
            eventId={eventId}
          />
        ),
      },
      {
        title: strings.pharmacistDetails,
        content: (
          <PharmacistProfile
            workshiftId={workshiftId}
            eventId={eventId}
            date={date}
            eventIds={eventIds as Array<string>}
            type={type}
          />
        ),
      },
      {
        title: strings.pharmacistRating,
        content: (
          <ViewPharmacistRating
            pharmacistId={pharmacistId}
            id={eventId}
            eventIds={eventIds as Array<string>}
            workshiftId={workshiftId}
            date={date}
            type={type}
          />
        ),
      },
    ],
    [ScreenTypes.updateWorkshift]: [
      {
        title: '',
        content: (
          <WorkshiftSummary
            bookingState={bookingState}
            eventIds={eventIds as Array<string>}
            type={type}
          />
        ),
      },
      {
        title: strings.updateWorkshift,
        content: (
          <UpdateWorkshift
            eventIds={eventIds as Array<string>}
            recType={recType}
            recurrenceId={recurrenceId}
          />
        ),
      },
    ],
    [ScreenTypes.fullServiceUpdateWorkshift]: [
      {
        title: strings.updateWorkshift,
        content: (
          <UpdateWorkshift
            eventIds={eventIds as Array<string>}
            recType={recType}
            recurrenceId={recurrenceId}
          />
        ),
      },
    ],
    [ScreenTypes.availableWorkshift]: [
      {
        content: <AvailableWorkshifts date={date} viewOnly={false} />,
      },
      {
        title: strings.editWorkshift,
        content: (
          <EditWorkshift fromScreen={fromScreen} workshiftId={eventId} />
        ),
      },
      {
        title: strings.viewDetails,
        content: (
          <ViewDetails
            type={type as AdminLegendVariant}
            workshiftId={eventId}
            back={back}
            pharmacyId={pharmacyId}
            date={date}
          />
        ),
      },
      {
        title: strings.matchingAvailabilitiesOfWorkshift,
        content: (
          <MatchingAvailabilities
            workshiftId={eventId}
            type={type}
            fromScreen={fromScreen}
          />
        ),
      },
      {
        title: strings.expressBooking,
        content: (
          <ExpressBooking
            id={eventId}
            workshiftId={workshiftId}
            type={type as Exclude<AdminLegendVariant, AdminLegendVariant.total>}
            fromScreen={fromScreen}
          />
        ),
      },
    ],
    [ScreenTypes.requestedList]: [
      {
        content: <RequestList date={date} />,
      },
      {
        title: strings.back,
        content: <RequestedPharmacistList workshiftId={eventId} />,
      },
      {
        title: strings.pharmacistDetails,
        content: (
          <ViewRequestedDetails
            workshiftId={workshiftId}
            bookingId={eventId}
            bookingIdList={eventIds as Array<string>}
          />
        ),
      },
      {
        title: strings.approveBooking,
        content: (
          <ApproveBooking
            eventIds={eventIds as Array<string>}
            workshiftId={workshiftId}
            bookingId={eventId}
            fromScreen={fromScreen as OtherScreens}
            type={type}
            back={back}
          />
        ),
      },
    ],
    [ScreenTypes.bookingList]: [
      {
        content: <BookingList date={date} />,
      },
      {
        title: strings.pharmacistDetails,
        content: (
          <ViewBookedDetails
            bookingIdList={eventIds as Array<string>}
            bookingId={eventId}
          />
        ),
      },
      {
        title: strings.pharmacistAvgRating,
        content: (
          <ViewAdminPharmacistRating
            pharmacistId={pharmacistId}
            bookingId={eventId}
            eventIds={eventIds as Array<string>}
            workshiftId={workshiftId}
            type={type}
            back={back}
          />
        ),
      },
    ],
    [ScreenTypes.profile]: [
      {
        title: strings.pharmacyProfileDetails,
        content: (
          <PharmacyViewProfile
            back={back}
            hasOwner={hasOwner}
            fromScreen={fromScreen}
            pharmacyId={pharmacyId}
            enableActions={enableActions as boolean}
          />
        ),
      },
      {
        title: strings.pharmacistProfileDetails,
        content: (
          <PharmacistViewProfile
            hasOwner={hasOwner}
            fromScreen={fromScreen}
            type={type}
            pharmacistId={pharmacistId}
            back={back}
            enableActions={enableActions as boolean}
          />
        ),
      },
      {
        title: strings.formatString(strings.editProfile, strings.pharmacy),
        content: (
          <PharmacyEditProfile
            fromScreen={fromScreen}
            hasOwner={hasOwner}
            back={back}
          />
        ),
      },
      {
        title: strings.formatString(strings.editProfile, strings.pharmacist),
        content: (
          <PharmacistEditProfile
            fromScreen={fromScreen}
            type={type}
            back={back}
            pharmacistId={pharmacistId}
            hasOwner={hasOwner}
          />
        ),
      },
      {
        title: strings.pharmacistGeographicAvailability,
        content: (
          <AdminGeographicAvailability
            fromScreen={fromScreen}
            type={type}
            back={back}
            pharmacistId={pharmacistId}
            hasOwner={hasOwner}
          />
        ),
      },
    ],
    [ScreenTypes.pendingCancellation]: [
      {
        title: '',
        content: (
          <PendingCancellationDetails
            type={fromScreen as PendingCancellationTabTypes}
            cancellationRequestId={eventId}
          />
        ),
      },
    ],
    [ScreenTypes.pharmacistList]: [
      {
        title: strings.adminNotes,
        content: (
          <AdminNotes
            notesType={notesType as AdminNotesType}
            id={pharmacistId}
            back={back}
          />
        ),
      },
      {
        title: strings.pharmacistBookingList,
        content: <PharmacistBookings pharmacistId={pharmacistId} />,
      },
      {
        title: strings.pharmacyBookingDetails,
        content: (
          <PharmacyBookingDetails
            pharmacistId={pharmacistId}
            bookingId={eventId}
          />
        ),
      },
      {
        title: strings.pharmacistAvailabilities,
        content: <ShowPharmacistAvailabilities pharmacistId={pharmacistId} />,
      },
      {
        title: strings.matchingAvailabilitiesOfWorkshift,
        content: (
          <MatchingWorkshifts
            pId={pharmacistId}
            availabilityId={eventId}
            code={date}
          />
        ),
      },
      {
        title: strings.history,
        content: (
          <AdminPharmacistHistory
            pharmacistId={pharmacistId}
            pharmacyId={pharmacyId}
          />
        ),
      },
      {
        title: strings.notesForPharmacy,
        content: (
          <AdminReplacementNotes
            pharmacistId={pharmacistId}
            pharmacyId={pharmacyId}
          />
        ),
      },
      {
        title: strings.listOfPharmacies,
        content: <ListOfPharmacies pharmacistId={pharmacistId} />,
      },
      {
        title: strings.formatString(strings.createEvent, strings.availability),
        content: (
          <AdminCreateAvailability
            pharmacistId={pharmacistId}
            pharmacistName={name as string}
          />
        ),
      },
    ],
    [ScreenTypes.pharmacyList]: [
      {
        title: strings.createWorkshift,
        content: (
          <AdminCreateWorkshift
            back={back}
            pharmacyId={pharmacyId}
            name={name as string}
          />
        ),
      },
      {
        title: strings.listOfPharmacists,
        content: <ListOfPharmacists pharmacyId={pharmacyId} />,
      },
      {
        title: strings.history,
        content: (
          <AdminPharmacyHistory
            pharmacistId={pharmacistId}
            pharmacyId={pharmacyId}
          />
        ),
      },
      {
        title: strings.notes,
        content: (
          <AdminPharmacyNotes
            pharmacistId={pharmacistId}
            pharmacyId={pharmacyId}
          />
        ),
      },
      {
        title: strings.adminNotes,
        content: (
          <AdminNotes
            notesType={notesType as AdminNotesType}
            id={pharmacyId}
            back={back}
          />
        ),
      },
    ],
    [ScreenTypes.adminWatchlists]: [
      {
        title: strings.watchlistedPharmacyWorkshifts,
        content: <AdminWatchlists pharmacistId={pharmacistId} />,
      },
    ],
    [ScreenTypes.broadcastList]: [
      {
        title: '',
        content: (
          <BroadcastGroup
            broadcastGroupId={eventId}
            enableDelete={enableDelete}
          />
        ),
      },
    ],
    [ScreenTypes.createVacation]: [
      {
        title: strings.createVacation,
        content: (
          <CreateVacation
            pharmacistId={pharmacistId}
            id={eventIds?.[0]}
            date={date}
            pharmacistName={name}
          />
        ),
      },
    ],
  };
};
