import React, { useEffect } from 'react';

import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  adminActions,
  adminResetValue,
  getAdminInfo,
  getAdminPicture,
  getAdminProfile,
  getAdminSettings,
  getAllBannerLogos,
  resetStatus,
} from '@pharmaplan/common';
import PrimaryToolbar from '../AdminToolbar/PrimaryToolbar';
import SecondaryToolbar from '../AdminToolbar/SecondaryToolbar';
import { navigationConfig } from '../AdminToolbar/config';
import {
  Constants,
  PrimaryAdminNavigation,
  SecondaryAdminNavigation,
} from '../../../helpers/Constants';
import useHandleBookingCancel from '../../../hooks/useHandleBookingCancel';
import Navbar from '../../Navbar';
import Drawer from '../../Drawer';

import styles from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  enableAddCalendarEvent,
  setMultipleAddEvents,
} from '../../../reducers/mainCalendarReducer';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useAdmin from '../../../hooks/useAdmin';
import { setPrimaryRoute } from '../../../actions/navbarActions';

export interface PrimaryItem {
  key: PrimaryAdminNavigation;
  label: string;
  path: string;
  subPaths: Array<SecondaryAdminNavigation>;
}

const resetValues = ['incompatibleMatches', 'incompatibleMatchesSearch'];
const { updateAdmin, createMultiple } = Constants.paths.admin;

const AdminHome = () => {
  const dispatch = useAppDispatch();
  const { mainContainer, content, contentContainer } = styles;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { can } = useAdminPermissions();
  const { isSuperAdmin } = useAdmin();

  const navigationConf = navigationConfig(can, isSuperAdmin);

  useHandleBookingCancel();

  const isIncompUpdate = pathname === Constants.paths.admin.updateIncompatibleMatch;
  const isIncomp = pathname === Constants.paths.admin.incompatibleMatch;
  const shouldKeep = isIncompUpdate || isIncomp;

  useEffect(() => {
    dispatch(setPrimaryRoute(navigationConf[0].key));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultClick = (item: PrimaryItem) =>
    () => {
      dispatch(setPrimaryRoute((item.key)));
      dispatch(setMultipleAddEvents(false));
      navigate(item.path);
    };

  const handleReset = () => {
    dispatch(adminResetValue(resetValues));
    dispatch(resetStatus([adminActions.getIncompatibleMatchList]));
  };

  useEffect(() => {
    dispatch(getAdminProfile());
    dispatch(getAdminInfo());
    dispatch(getAdminPicture());
    dispatch(getAllBannerLogos());
    if (pathname !== updateAdmin) {
      dispatch(getAdminSettings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!shouldKeep) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldKeep]);

  useEffect(() => {
    const isMultiplePath = pathname === createMultiple;
    dispatch(setMultipleAddEvents(isMultiplePath));
    dispatch(enableAddCalendarEvent(isMultiplePath));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box sx={mainContainer} component="div">
      <Grid sx={contentContainer} item sm={11}>
        <Drawer />

        {/* TopBar */}
        <Box component="div">
          <Navbar
            UpperToolbar={<PrimaryToolbar defaultClick={defaultClick} />}
            BottomToolbar={
              <SecondaryToolbar />
            }
          />
        </Box>
        {/* Content */}
        <Box sx={content}>
          <Outlet />
        </Box>
      </Grid>
    </Box>
  );
};

export default AdminHome;
