import React, { FC } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { ReactComponent as StarFilled } from '@pharmaplan/common/assets/icons/starFilled.svg';
import { BookingState, ICalendarEventType, PharmacyLegendVariant } from '@pharmaplan/common';

import OptimizedAvatar from '../../../common/OptimizedAvatar/OptimizedAvatar';
import CustomButton from '../../../common/CustomButton/CustomButton';
import WithSkeleton from '../../../common/WithSkeleton';
import CustomChip from '../../../common/CustomChip';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { renderScreen } from '../../../../actions/drawerActions';
import { approvalTime, formatDate } from '../../../Reports/helpers';
import useAdmin from '../../../../hooks/useAdmin';
import {
  Constants,
  ScreenTypes,
  SkeletonVariants,
} from '../../../../helpers/Constants';
import { ReactComponent as FavIcon } from '../../../../assets/svg/favIconWithBorder.svg';
import { ReactComponent as Ticker } from '../../../../assets/svg/ticker.svg';
import strings from '../../../../localization';

import classes from './styles';

interface IPharmacistCardContent {
  cardContent: {
    firstName: string;
    lastName: string;
    avatar: string;
    requestedDate: string;
    rating: number;
    pharmacistId: string;
    type?: ICalendarEventType;
    eventId?: string;
    isFavorite: boolean;
    isSummary?: boolean;
    bookingState?: BookingState;
    bookingStateExpiration?: string;
    timezone?: string;
    date?: string;
    workshiftId?: string;
    eventIds?: Array<string>;
  };
  successAction: string;
  imageSuccessAction?: string;
  screenType: ScreenTypes;
}

const { workshiftSummary, pharmacistProfile } = ScreenTypes;
const { PendingWithPharmacist, UpdatedTiming } = BookingState;
const { booked, requested } = PharmacyLegendVariant;
const { rounded, text: skeletonText } = SkeletonVariants;

const {
  variant: { text },
} = Constants;

const PharmacistCardContent: FC<IPharmacistCardContent> = ({
  cardContent,
  screenType,
  successAction,
  imageSuccessAction,
}) => {
  const dispatch = useAppDispatch();
  const { isAdmin } = useAdmin();

  const {
    firstName,
    lastName,
    avatar,
    requestedDate,
    rating,
    type,
    pharmacistId,
    eventId,
    isFavorite,
    isSummary,
    bookingState,
    bookingStateExpiration,
    timezone,
    date,
    workshiftId,
    eventIds,
  } = cardContent ?? {};

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: skeletonText,
  })(Typography);

  const SkeletonChip = WithSkeleton({
    successAction,
    variant: rounded,
  })(Box);

  const formattedDate = formatDate(requestedDate, strings.requestedAgo);

  const isPharmacistProfile = screenType === pharmacistProfile;
  const isWorkshiftSummary = screenType === workshiftSummary;

  const isRequested = type === requested;
  const isBooked = type === booked;

  const isPendingWithPharmacist = bookingState === PendingWithPharmacist;
  const isUpdatedTiming = bookingState === UpdatedTiming;

  const viewRating = () => {
    dispatch(
      renderScreen({
        eventId,
        eventIds,
        pharmacistId,
        workshiftId,
        date,
        screenNumber: 4,
        type,
        screenType: workshiftSummary,
      }),
    );
  };

  const viewAdminRating = () => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        screenType: ScreenTypes.bookingList,
        workshiftId,
        pharmacistId,
        eventId,
        eventIds,
        type,
      }),
    );
  };

  const confirmTime = approvalTime(bookingStateExpiration ?? '');

  const confirmBefore = `${strings.before} ${confirmTime} ${timezone}`;

  const renderTime = () => {
    if (isPendingWithPharmacist || isUpdatedTiming) {
      return (
        <Box sx={classes.pharmacyCard}>
          <Ticker />
          <SkeletonTypography sx={classes.confirmTime}>
            {confirmBefore}
          </SkeletonTypography>
        </Box>
      );
    }

    return <Box />;
  };

  const renderLink = () => {
    if (isPharmacistProfile) {
      return (
        <SkeletonTypography sx={classes.ratingContainer}>
          <StarFilled width={11} height={11} />
          <SkeletonTypography sx={classes.ratingText}>
            {rating.toPrecision(2)}
          </SkeletonTypography>

          <CustomButton
            customButtonStyle={classes.customLink}
            label={`(${strings.viewRating})`}
            onClick={isAdmin ? viewAdminRating : viewRating}
            variant={text}
          />
        </SkeletonTypography>
      );
    }

    if (isWorkshiftSummary) {
      return renderTime();
    }

    return <Box />;
  };

  const renderChip = () => {
    if (isRequested) {
      return isPendingWithPharmacist ? (
        <SkeletonChip>
          <CustomChip type={requested} label={strings.confirmationPending} />
        </SkeletonChip>
      ) : (
        <SkeletonTypography sx={classes.requestedText}>
          {formattedDate}
        </SkeletonTypography>
      );
    }

    if (isBooked) {
      return (
        <SkeletonChip>
          <CustomChip type={booked} label={strings.booked} />
        </SkeletonChip>
      );
    }

    return <Box />;
  };

  const gotoPharmacistProfile = () => {
    dispatch(
      renderScreen({
        eventId,
        type,
        eventIds,
        screenNumber: 3,
        screenType: workshiftSummary,
      }),
    );
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.avatarContainer}>
        <OptimizedAvatar
          avatarSize={76}
          showFullImage
          src={avatar}
          successAction={imageSuccessAction ?? successAction}
          noSpacing
        />
        <Box sx={classes.favIconContainer}>{isFavorite && <FavIcon />}</Box>
      </Box>

      <Box sx={classes.infoContainer}>
        <Box sx={classes.titleContainer}>
          <SkeletonTypography sx={classes.pharmacist}>
            {`${firstName} ${lastName}`}
          </SkeletonTypography>
        </Box>
        {renderChip()}
        {renderLink()}
      </Box>

      {isSummary && (
        <Box sx={classes.iconContainer}>
          <IconButton sx={classes.rightArrow} onClick={gotoPharmacistProfile}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default PharmacistCardContent;
