import { NavigateFunction } from 'react-router-dom';
import {
  ReportRequestTypes,
  setStartTour,
  TypeOfUser,
} from '@pharmaplan/common';
import phoneIcon from '@pharmaplan/common/assets/icons/webUrgentCallIcon.svg';
import questionMarkIcon from '../../assets/svg/filledQuestionMark.svg';
import profileIcon from '../../assets/svg/profileIcon.svg';
import logOffIcon from '../../assets/svg/logOffIcon.svg';
import passwordChangeIcon from '../../assets/svg/passwordChangeIcon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/svg/phoneIcon.svg';
import { ReactComponent as QuestionMarkIcon } from '../../assets/svg/questionMarkIcon.svg';
import { Constants } from '../../helpers/Constants';
import { ReactComponent as DashboardIcon } from '../../assets/svg/dashboardIcon.svg';
import { ReactComponent as MyReplacemnts } from '../../assets/svg/myReplacemnts.svg';
import ReplayIcon from '../../assets/svg/replay.svg';

import strings from '../../localization';
import { logoutHandler } from '../../helpers/Functions';
import { store } from '../../store';

export const menuItems = (
  navigate: NavigateFunction,
  dispatch: typeof store.dispatch,
) =>
  [
    {
      key: '1',
      label: strings.profile,
      icon: profileIcon,
      onClick: () => {
        navigate(Constants.paths.profile);
      },
    },
    {
      key: '2',
      label: strings.changePassword,
      icon: passwordChangeIcon,
      onClick: () => {
        navigate(Constants.paths.changePassword);
      },
    },
    {
      key: '3',
      label: strings.replayWalkthrough,
      icon: ReplayIcon,
      onClick: () => {
        navigate(Constants.paths.dashboard);
        dispatch(setStartTour(true));
      },
    },
    {
      key: '4',
      label: strings.faq,
      icon: questionMarkIcon,
      onClick: () => {
        navigate(Constants.paths.help);
      },
    },
    {
      key: '5',
      label: Constants.telephone,
      icon: phoneIcon,
      onClick: () => {
        window.open(Constants.paths.telephone, '_self');
      },
    },
    {
      key: '6',
      label: strings.logout,
      icon: logOffIcon,
      onClick: logoutHandler,
    },
  ];

export const adminMenuItems = (navigate: NavigateFunction) =>
  [
    {
      key: '1',
      label: strings.profile,
      icon: profileIcon,
      onClick: () => {
        navigate(Constants.paths.admin.profile);
      },
    },
    {
      key: '2',
      label: strings.changePassword,
      icon: passwordChangeIcon,
      onClick: () => {
        navigate(Constants.paths.admin.changePassword);
      },
    },
    {
      key: '3',
      label: strings.logout,
      icon: logOffIcon,
      onClick: logoutHandler,
    },
  ];

export const pharmacyAdminMenuItems = (navigate: NavigateFunction) =>
  [
    {
      key: '1',
      label: strings.profile,
      icon: profileIcon,
      onClick: () => {
        navigate(Constants.paths.pharmacyAdmin.profile);
      },
    },
    {
      key: '2',
      label: strings.changePassword,
      icon: passwordChangeIcon,
      onClick: () => {
        navigate(Constants.paths.pharmacyAdmin.changePassword);
      },
    },
    {
      key: '4',
      label: strings.faq,
      icon: questionMarkIcon,
      onClick: () => {
        navigate(Constants.paths.pharmacyAdmin.help);
      },
    },
    {
      key: '5',
      label: Constants.telephone,
      icon: phoneIcon,
      onClick: () => {
        window.open(Constants.paths.telephone, '_self');
      },
    },
    {
      key: '3',
      label: strings.logout,
      icon: logOffIcon,
      onClick: logoutHandler,
    },
  ];

export const reportItems = (navigate: NavigateFunction, userType: TypeOfUser) =>
  (userType === TypeOfUser.pharmacist
    ? [
      {
        key: '1',
        label: strings.bookingReport,
        onClick: () => {
          navigate(Constants.paths.reports, {
            state: {
              type: ReportRequestTypes.booked,
              title: strings.bookingReport,
            },
          });
        },
      },
      {
        key: '2',
        label: strings.availableWorkshifts,
        onClick: () => {
          navigate(Constants.paths.reports, {
            state: {
              type: ReportRequestTypes.workshift,
              title: strings.availableWorkshifts,
            },
          });
        },
      },
      {
        key: '3',
        label: strings.requestedWorkshifts,
        onClick: () => {
          navigate(Constants.paths.reports, {
            state: {
              type: ReportRequestTypes.requested,
              title: strings.requestedWorkshifts,
            },
          });
        },
      },
      {
        key: '4',
        label: strings.availabilitesReports,
        onClick: () => {
          navigate(Constants.paths.reports, {
            state: {
              type: ReportRequestTypes.availability,
              title: strings.availabilitesReports,
            },
          });
        },
      },
      {
        key: '5',
        label: strings.incomeReport,
        onClick: () => {
          navigate(Constants.paths.incomeReport);
        },
      },
      {
        key: '6',
        label: strings.watchlistReport,
        onClick: () => {
          navigate(Constants.paths.reports, {
            state: {
              type: ReportRequestTypes.watchlist,
              title: strings.watchlistReport,
            },
          });
        },
      },
    ]
    : [
      {
        key: '1',
        label: strings.bookingReport,
        onClick: () => {
          navigate(Constants.paths.reports, {
            state: {
              type: ReportRequestTypes.booked,
              title: strings.bookingReport,
            },
          });
        },
      },
      {
        key: '2',
        label: strings.postedWorkshiftReport,
        onClick: () => {
          navigate(Constants.paths.reports, {
            state: {
              type: ReportRequestTypes.workshift,
              title: strings.postedWorkshiftReport,
            },
          });
        },
      },
    ]);

export const langItems = (changeLang: (lang: string) => void) =>
  [
    {
      key: '1',
      label: strings.en,
      onClick: () => {
        changeLang('en');
      },
    },
    {
      key: '2',
      label: strings.fr,
      onClick: () => {
        changeLang('fr');
      },
    },
  ];

export const toolbarItems = (userType: TypeOfUser) => {
  const isPharmacy = userType === TypeOfUser.pharmacy;
  const myReplacementsLabel = isPharmacy
    ? strings.myReplacementPharmacists
    : strings.myReplacements;

  return [
    {
      key: '1',
      Icon: DashboardIcon,
      label: strings.dashboard,
      subPaths: [Constants.paths.mapDashboard],
      path: `${Constants.paths.calendarDashboard}`,
      id: 'dashboard',
    },
    {
      key: '2',
      Icon: MyReplacemnts,
      label: myReplacementsLabel,
      path: Constants.paths.myReplacements,
      subPaths: [Constants.paths.notes, Constants.paths.history],
      id: 'myReplacements',
    },
  ];
};

export const contactIcons = [
  {
    Icon: PhoneIcon,
  },
  { Icon: QuestionMarkIcon },
];
