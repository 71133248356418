import {
  TimeFormat,
  HelpTypes,
  PendingCancellationStatus,
  WorkshiftStatus,
  PharmacyLegendVariant,
  PharmacistLegendVariant,
  AdminTypes,
  PermissionsOfAdmin,
  PharmacyFilterWorkshifts,
  BroadcastNotificationType,
  LogActionTypes,
  PerformerTypes,
  ActivityLogActionTypes,
  ActivityLogModuleTypes,
  Language,
  BookingState,
} from '../helpers/Constants';
import { IAvailabilityModel, IGeneralPaginationResponse } from './Models';
import {
  IAllLanguages,
  IContactPreferences,
  IFilterSoftware,
  IPharmacistCapacity,
  IProvinces,
  IRegions,
  IVolumes,
} from './PharmacistModels';
import { IPharmacistCapacityDetails } from './PharmacyModels';

export interface IAdminCalendarParams {
  startDate: string;
  endDate: string;
}

export interface GenericPaginationAPIParams {
  paging: {
    page: number;
  };
}

export interface GenericPaginationResponse {
  page: number;
  itemPerPage: number;
  totalCount: number;
}

export type ICalendarEventType =
  | `${PharmacyLegendVariant}`
  | `${PharmacistLegendVariant}`
  | `${AdminLegendVariant}`;

export interface GenericPaginationModelAPIParams {
  pagingModel: {
    page: number;
    itemPerPage?: number;
  };
}

export interface IPharmacyAdminPharmacies {
  userId: string;
  id: string;
  userName: string;
  email: string;
  logoId: string;
}

export interface IAdminCalendar {
  id: string;
  type: string;
  bookingId: string;
  workShiftId: string;
  startDate: string;
  endDate: string;
  logoId: string;
  city: string;
  softwareName: string;
  hourlyRate: number;
  isMatchingAvailabilityExist: boolean;
  pharmacyName: string;
}

export interface IAdminActiveUsersData {
  id: string;
  userId: string;
  name: string;
  city: string;
  logoId: string;
  image: string;
  pharmacyCount?: number;
  pharmacistCount?: number;
}

export interface IActiveUsers extends IGeneralPaginationResponse {
  data: Array<IAdminActiveUsersData>;
}

export interface IAvailableWorkshiftData {
  workshiftId: string;
  pharmacyId: string;
  name: string;
  startDate: string;
  endDate: string;
  city: string;
  hourlyRate: number;
  pharmacistHourlyRate: number;
  pharmacyHourlyRate: number;
  softwareName: string;
  logoId: string;
  isRequested: boolean;
}

export interface IAdminWorkshiftDetails {
  workshiftId: string;
  pharmacyId: string;
  pharmacyName: string;
  banner: string;
  softwareName: string;
  softwareId: string;
  pharmacyCreatedDate: string;
  startDate: string;
  endDate: string;
  note: string;
  paidHourlyRate: number;
  city: string;
  address: string;
  province: string;
  region: string;
  latitude: string;
  longitude: string;
  pharmacyHourlyRate: number;
  logoId: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactMobile: string;
  contactPhoneExtension: string;
  travelAllowance: number;
  mealAllowance: number;
  emergencyFees?: number;
  accommodationAllowance?: number;
  pharmacistHourlyRate: number;
  languageId: string;
  notes: string;
}

export enum AdminLegendVariant {
  posted = 'posted',
  requested = 'requested',
  booked = 'booked',
  total = 'total',
}

export interface IAdminEditWorkshiftParams {
  workshiftId: string;
  startDate: string;
  endDate: string;
  paidHourlyRate?: number;
  travelAllowance?: number;
  mealAllowance?: number;
  accommodationAllowance?: number;
  emergencyFees?: number;
  note: string;
}

interface IGenericListData {
  bookingId: string;
  pharmacyId: string;
  workshiftId: string;
  logoId: string;
  pharmacistName: string;
  image: null | string;
  pharmacyName: string;
  startDate: string;
  endDate: string;
  workshiftHourlyRate: number;
  softwareName: string;
  pharmacyCity: string;
  pharmacistCity: string;
  pharmacistHourlyRate: number;
  pharmacistId: string;
}
export interface IAdminRequestData {
  workshiftId: string;
  pharmacyId: string;
  pharmacyName: string;
  city: string;
  softwareName: string;
  workshiftHourlyRate: number;
  pharmacyHourlyRate: number;
  logoId: null | string;
  profilePictures: Array<string | null>;
  count: number;
  startDate: string;
}

export interface IAdminRequestedPharmacistsData extends IGenericListData {
  workshiftId: string;
  pharmacistCity: string;
  replacementType: number;
  pharmacyHourlyRate: number;
}

export interface IBookedPharmacistData extends IGenericListData {
  paidHourlyRate: number;
  pharmacyHourlyRate: number;
}

export interface IAdminRating {
  totalRating: number;
  count: number;
  pharmacistId: string;
}
export interface IAdminSoftware {
  software: IFilterSoftware;
  pharmacistId: string;
  softwareId: string;
  levelInternal: number;
  level: number;
}

export interface IAdminRequestedDetailsData {
  pharmacistId: string;
  firstName: string;
  lastName: string;
  languageId: string;
  language: IAllLanguages;
  pharmacistCapacity: IPharmacistCapacityDetails;
  phone: string;
  mobile: string;
  address: string;
  province: string;
  city: string;
  postalCode: string;
  experience: number;
  startDate: string;
  endDate: string;
  paidHourlyRate: number;
  email: string;
  bookingCost?: number;
  travelAllowance?: number;
  mealAllowance?: number;
  accommodationAllowance?: number;
  licenseNumber: string;
  emergencyFees?: number;
  softwares: Array<IAdminSoftware>;
  rating: {
    totalRating: number;
    count: number;
  } | null;
  profilePicture: string | null;
}
export interface IAdminIgnoreRequestParams {
  bookingId: string;
  note: string;
  sendEmailToPharmacist: boolean;
}

export interface IAdminIgnoreData {
  bookingId: string;
  pharmacyId: string;
  pharmacistId: string;
  pharmacyName: string;
  pharmacistName: string;
  startDate: string;
  endDate: string;
}

export interface IPharmaList {
  id: string;
  key: string | null;
  name: string;
}

export interface IAdminApproveDetailsData {
  bookingId: string;
  pharmacyId: string;
  pharmacistId: string;
  pharmaList: Array<IPharmaList>;
  pharmaId: string;
  pharmacyName: string;
  pharmacistName: string;
  startDate: string;
  endDate: string;
  travelAllowance?: number;
  mealAllowance?: number;
  accommodationAllowance?: number;
  pharmacyHourlyRate: number;
  pharmacistHourlyRate: number;
  emergencyFees?: number;
}

interface IAdminApproveBaseParams {
  pharmaId: string;
  notesForSalesOrder: string;
  pharmacistHourlyRate: number;
  pharmacyHourlyRate: number;
  travelAllowance: number;
  mealAllowance: number;
  accommodationAllowance: number;
  emergencyFees: number;
  sendEmailToPharmacist: boolean;
  sendEmailToPharmacy: boolean;
}
export interface IAdminApproveParams extends IAdminApproveBaseParams {
  bookingId: string;
}
export interface IAdminBookAvailabilityParams extends IAdminApproveBaseParams {
  workshiftId: string;
  availabilityId: string;
}

export interface IAdminSearchAPIParams {
  userTypes: HelpTypes[] | null;
  query: string;
  active?: boolean;
  isForDeletedUsers?: boolean;
}

export interface IMatchingAvailabilitiesData {
  availableTimeId: string;
  endDate: string;
  pharmacistId: string;
  pharmacistName: string;
  selectedDate: string;
  startDate: string;
  isRequested: boolean;
}

export interface IMatchingAvailabilities {
  city: string;
  endDate: string;
  logoId: string;
  matchingAvailabilities: Array<IMatchingAvailabilitiesData>;
  pharmacyName: string;
  startDate: string;
  typeReplacement: number;
  workShiftId: string;
}

export interface IAdminBookedPharmacistDetails {
  pharmacistId: string;
  firstName: string;
  lastName: string;
  languageId: string;
  language: IAllLanguages;
  pharmacistCapacity: IPharmacistCapacityDetails;
  phone: string;
  mobile: string;
  address: string;
  province: string;
  city: string;
  postalCode: string;
  experience: number;
  licenseNumber: string;
  startDate: string;
  endDate: string;
  paidHourlyRate: number;
  email: string;
  createDate: string;
  bookingCost?: number;
  travelAllowance?: number;
  mealAllowance?: number;
  accommodationAllowance?: number;
  emergencyFees?: number;
  softwares: Array<IAdminSoftware>;
  rating: IAdminRating;
  profilePicture: string;
}

export interface IRequestCancellationParams {
  bookingId: string;
  reason: string;
}

export interface IRequestCancellationData {
  startDate: string;
  endDate: string;
  pharmacyName: string;
  success: null | boolean;
  bookingId?: string;
  cancelationRequestId?: string;
  code?: string;
  message?: string;
}
export interface IPharmacyDetails {
  pharmacyId: string;
  email: string;
  languageId: string;
  timeFormat: TimeFormat;
  name: string;
  logoId: string;
  banner: string;
  pharmacyShortName: string | null;
  number: string | null;
  softwareId: string;
  selectedContactPreferences: Array<string>;
  sendReminder: boolean;
  code: string;
  administrator: string | null;
  weekVolume: string;
  weekendVolume: string;
  weekDayPharmacists: string;
  weekNightPharmacists: string;
  weekendDayPharmacists: string;
  weekendNightPharmacists: string;
  weekDayTechs: string;
  weekNightTechs: string;
  weekendDayTechs: string;
  weekendNightTechs: string;
  phone: string;
  emergencyPhone: string;
  postalCode: string;
  fax: string;
  address: string;
  city: string;
  province: string;
  region: string;
  regionId: string;
  contactEmail: string;
  primaryContactName: string;
  primaryContactPhone: string;
  primaryContactPhoneExt: string;
  primaryContactMobile: string | null;
  primaryContactEmail: string;
  secondaryContactName: string;
  secondaryContactPhone: string | null;
  secondaryContactPhoneExt: string | null;
  secondaryContactMobile: string | null;
  secondaryContactEmail: string | null;
  accountingContactName: string;
  accountingContactPhone: string;
  accountingContactPhoneExt: string;
  accountingContactMobile: string;
  accountingContactEmail: string;
  adminNotes: string;
  hourlyRate: number;
  useLSE: boolean;
  hourlyRateTech: number;
  pharmacyChainId: string;
  pharmacyHourlyRate: number;
  noCancellationPeriod: number;
  pharmaPlanPercentFees: number;
  cancellationPercentFees: number;
  latitude: null | number;
  longitude: null | number;
  communicationEmail: string;
}

export interface IPharmacyDetailModel {
  softwares: Array<IFilterSoftware>;
  contactPreferences: Array<IContactPreferences>;
  pharmacy: IPharmacyDetails;
  provinces: Array<IProvinces>;
}

export interface IPharmacistDetailsBaseModel {
  email: string;
  preferdLanguage: string;
  timeFormat: number;
  firstName: string;
  lastName: string;
  licenseNumber: string;
  pharmacistCapacityId: string;
  graduationYear: number;
  yearsOfExperience: number;
  incorporated: boolean;
  englishLevel: number | string;
  frenchLevel: number | string;
  otherLanguages: string;
  address: string;
  phone: string;
  mobile: string;
  city: string;
  postalCode: string;
  province: string;
  prefferedDistanceRange: number;
  availabilityReminder: boolean;
  preferredReplacementDuration: string;
  preferredWorkingDays: string | string[];
  picture: string;
  hourlyRate: number;
  code: string;
  adminNotes: string;
  pictureName: string;
  rating: IAdminRating;
  resumeName: string;
  noCancellationPeriod: number;
  bookingStateExpiration: number;
  cancellationPercentFees: number;
  latitude: null | number;
  longitude: null | number;
  pharmacistId: string;
}

export interface IAdminPharmacistDetails extends IPharmacistDetailsBaseModel {
  selectedSoftwares: Array<IAdminSoftware>;
}

export interface ISoftwareSubmitParams {
  softwareId: string;
  expLevel: number;
}

export interface IPharmacistSubmission extends IPharmacistDetailsBaseModel {
  selectedSoftwares: Array<ISoftwareSubmitParams>;
}

export interface IPharmacistDetailsModel {
  pharmacist: IAdminPharmacistDetails;
  provinces: Array<IProvinces>;
  prefferedReplacementDurations: Array<string>;
  pharmacistCapacities: Array<IPharmacistCapacity>;
}

export interface IAdminSearchResponse {
  id: string;
  userId: string;
  userName: string;
  email: string;
}

export interface IExpressBookingAPIParams {
  bookingId?: string;
  workshiftId?: string;
  pharmacyId: string;
  pharmacistId: string;
  startDate: string;
  endDate: string;
  notes: string;
  notesForSalesOrder: string;
  pharmacistHourlyRate: number;
  pharmacyHourlyRate: number;
  travelAllowance?: number;
  mealAllowance?: number;
  accommodationAllowance?: number;
  emergencyFees?: number;
  sendEmailToPharmacist: boolean;
  sendEmailToPharmacy: boolean;
}

export interface IValidateExpressBookingAPIParams {
  pharmacistId: string;
  pharmacyId: string;
}

export interface IAdminPendingActivationAPIParams {
  startDate?: string | null;
  endDate?: string | null;
  page: number;
  userKey?: string | null;
  userType?: HelpTypes | null;
  ownerId?: string;
}

export interface IAdminTakeOwnershipAPIParams {
  userType: HelpTypes;
  id: string;
}

export interface IAdminPendingActivationsData {
  userId: string;
  personnelId: string;
  ownerId: null | string;
  email: string | null;
  userName: string;
  contactName: null | string;
  createdDate: string;
  deletedDate?: string;
  userType?: HelpTypes;
  ownerName: string | null;
  enableActions: boolean;
  logoId?: string;
}

export interface IAdminPendingActivationsResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminPendingActivationsData>;
}

export interface ISubmitPharmacistDetails extends IPharmacyDetails {
  pharmacyId: string;
}

export interface IAdminCreateSoftwareApiParams {
  name: string;
  description: string;
  published: boolean;
}

export interface IAdminUpdateSoftwareApiParams
  extends IAdminCreateSoftwareApiParams {
  softwareId: string;
}

export interface IAdminCreatePharmacistCapacityApiParams {
  from: number;
  to: number;
  published: boolean;
}

export interface IAdminUpdatePharmacistCapacityApiParams
  extends IAdminCreatePharmacistCapacityApiParams {
  pharmacistCapacityId: string;
}

interface IAdminPharmacistCapacity {
  pharmacistCapacityId: string;
  from: number;
  to: number;
  published: boolean;
}

export interface IAdminPharmacistCapacityList extends IAdminPharmacistCapacity {
  inUse: boolean;
}

export interface IAdminPharmacistCapacityItem extends IAdminPharmacistCapacity {
  createDate: string;
  updateDate: string | null;
}

export interface IAdminContactPreferences {
  contactPreferenceId: string;
  name: string;
  published: boolean;
  inUse: boolean;
}

export interface IAdminConfiguration {
  pharmaPlanConfigurationId: string;
  ratePharmacistDays: number;
  minHourWorkshift: number;
  pharmacyRate: number;
  pharmacistRate: number;
  representativeName: string;
  representativeEmail: string;
  representativePhone: string;
  representativePhoneExt: string;
  salesForceUserName: string;
  salesForcePassword: string;
  salesForceSecurityToken: string;
  salesForceURL: string;
  envoiceBCCAdmin: boolean;
  salesForceActive: boolean;
  showBookingDetails: boolean;
  technicianRate: number;
  noCancellationPeriod: number;
  bookingStateExpiration: number;
  pharmaPlanPercentFees: number;
  cancellationPercentFees: number;
  freeCancellationPeriod: number;
  reminder1After: number;
  reminder2Before: number;
}

export interface ISalesforceAdmin {
  adminPharmaPlanId: string;
  name: string;
  email: string;
  phone: null | string;
  received: boolean;
}
export interface IAdminCreatePharmacyChainApiParams {
  name: string;
  published: boolean;
  form: any;
}

export interface IAdminUpdatePharmacyChainApiParams
  extends IAdminCreatePharmacyChainApiParams {
  pharmacyChainId: string;
  isLogoChanged: boolean;
}

export interface IAdminPharmacyChainResponse {
  pharmacyChainId: string;
  logoId: string;
  name: string;
  published: boolean;
  logoImage: string | null;
  inUse: boolean;
  imageName: string;
}

export interface IPharmacistRegionList {
  allRegions: Array<IRegions>;
  selectedRegions: Array<string>;
}

export interface IUpdatePharmacistRegions {
  pharmacistId: string;
  regions: Array<string>;
}

export interface IPendingCancellationApiParmas {
  startDate?: string | null;
  endDate?: string | null;
  code?: string;
  page: number;
  pharmacyText?: string;
  pharmacistText?: string;
}

export interface IPendingCancellationData {
  cancellationRequestId: string;
  cancellationId: string | null;
  cancellationRejectionDate: string | null;
  bookingId: string;
  requestedBy: string;
  cancellationRequestDate: string;
  selectedDate: string;
  cancellationDate: string | null;
  requestedByName: string;
  status: PendingCancellationStatus;
  requestCode: string;
  rejectionReason: string | null;
  cancellationCode: string | null;
  requsetCancellationReason: string;
  cancellationReason: string | null;
  pharmacyId: string;
  pharmacyName: string;
  isPharmacyDeleted: boolean;
  pharmacistId: string;
  pharmacistName: string;
  isPharmacistDeleted: boolean;
}

export interface IPendingCancellationsResponse
  extends IGeneralPaginationResponse {
  data: Array<IPendingCancellationData>;
}
export interface IPharmacistResume {
  fileName: string;
  contentType: string;
  resume: string;
}

export interface IPendingCancellationCodeDetails {
  cancellationRequestId: string;
  cancellationId: string;
  bookingId: string;
  requestedBy: string;
  approvedBy: string | null;
  status: PendingCancellationStatus;
  requestType: string;
  requestReason: string;
  cancellationReason: string | null;
  approvedByName: string | null;
  selectedDate: string;
  cancellationDate: string;
  requestCode: string;
  confirmationCode: string | null;
  pharmacyId: string;
  pharmacyName: string;
  pharmacistId: string;
  pharmacistName: string;
  workshiftCode: string;
  rejectedBy: string | null;
  rejectionReason: string | null;
  rejectedByName: string | null;
  cancellationRequestDate: string;
  cancellationRejectionDate: string | null;
  availabilityCode: string;
}

export interface IAdminApprovePendingCancellationApiParams {
  cancellationId: string;
  notes: string;
  sendEmailToPharmacist: boolean;
  sendEmailToPharmacy: boolean;
}

export interface IAdminRejectPendingCancellationApiParams {
  cancellationId: string;
  notes: string;
}

export interface IAdminActivePharmacistListApiParams {
  page: number;
  userText?: string;
}

export interface IAdminCreateAvailabilityApiParams {
  pharmacistId: string;
  model: IAvailabilityModel;
}

export interface IAdminPharmacistListData {
  pharmacistId: string;
  code: string;
  name: string;
  email: string;
  mobile: string;
  city: string;
}

export interface IAdminPharmacistListApiResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminPharmacistListData>;
}
export interface IPendingCancellationCodeDetails {
  cancellationRequestId: string;
  cancellationId: string;
  bookingId: string;
  requestedBy: string;
  approvedBy: string | null;
  requestType: string;
  requestReason: string;
  cancellationReason: string | null;
  approvedByName: string | null;
  selectedDate: string;
  cancellationDate: string;
  requestCode: string;
  confirmationCode: string | null;
  pharmacyId: string;
  pharmacyName: string;
  pharmacistId: string;
  pharmacistName: string;
  workshiftCode: string;
  availabilityCode: string;
}

export interface ICancelledBookingDetails
  extends IPendingCancellationCodeDetails {
  rejectedBy: string | null;
  rejectionReason: string | null;
  rejectedByName: string | null;
  cancellationRequestDate: string;
  cancellationRejectionDate: string | null;
}
export interface IAdminNoteApiBase {
  notes: string;
}

export interface IAdminCreateNoteApiParams extends IAdminNoteApiBase {
  pharmacistId: string;
}

export interface IAdminCreatePharmacyNoteApiParams extends IAdminNoteApiBase {
  pharmacyId: string;
}

export interface IAdminUpdateNoteApiParams extends IAdminNoteApiBase {
  adminPharmacistNoteId: string;
}

export interface IAdminUpdatePharmacyNoteApiParams extends IAdminNoteApiBase {
  adminPharmacyNoteId: string;
}

export interface IAdminNotesResponseBase {
  note: string;
  createDate: string;
  updateDate: string | null;
}

export interface IAdminNotesResponse extends IAdminNotesResponseBase {
  adminPharmacistNoteId: string;
  pharmacistId: string;
}

export interface IAdminPharmacyNotesResponse extends IAdminNotesResponseBase {
  adminPharmacyNoteId: string;
  pharmacyId: string;
}

export interface IAdminPharmacistBookingsAPIParams {
  pharmacistId: string;
  paging: {
    page: number;
  };
}

export interface IAdminPharmacistBookingData {
  bookingId: string;
  pharmacistId: string;
  pharmacyId: string;
  logoId: string;
  name: string;
  startDate: string;
  endDate: string;
  city: string;
  paidHourlyRate: number;
}

export interface IAdminPharmacistBookingResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminPharmacistBookingData>;
}

export interface IAdminPharmacistBooking {
  bookingId: string;
  startDate: string;
  endDate: string;
  paidHourlyRate: number;
  cost: number;
  travelAllowance: number;
  mealAllowance: number;
  accommodationCosts: number;
  emergencyCosts: number;
  pharmaPlanPercentFees: string;
  logoId: string;
  languageId: string;
  latitude: string;
  longitude: string;
  pharmacyName: string;
  pharmacyChainName: string;
  number: string;
  softwareName: string;
  languageCode: string;
  createDate: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  city: string;
  province: string;
  region: string;
  postalCode: string;
  pharmacyHourlyRate: number;
  pharmacistHourlyRate: number;
}

export interface IAdminDeletePharmacistAvailabilityAPIParams {
  availabilityIds: Array<string>;
  notes: string;
}

export interface IAdminShowAvailabilitiesData {
  availableTimeId: string;
  pharmacistId: string;
  code: string;
  startDate: string;
  endDate: string;
}

export interface IAdminShowAvailabilitiesResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminShowAvailabilitiesData>;
}

export interface IMatchingWorkshiftAvailabilityData {
  workShiftId: string;
  pharmacyId: string;
  logoId: string;
  pharmacyName: string;
  selectedDate: string;
  startDate: string;
  booked: boolean;
  endDate: string;
  isRequested: boolean;
}

export interface IMatchingWorkshiftsResponse {
  availableTimeId: string;
  pharmacistName: string;
  pharmacistId: string;
  startDate: string;
  endDate: string;
  city: string;
  mobile: string;
  matchingAvailabilities: Array<IMatchingWorkshiftAvailabilityData>;
}

export interface IDeactivePharmacistApiParams {
  pharmacistId: string;
  reason: string;
}
export interface IAdminPharmacistPictures {
  pharmacistId: string;
  image: string;
}

export interface IPendingActivationsCount {
  pharmacistCount: number;
  pharmacyCount: number;
}

export interface IOwnersList {
  userId: string;
  userName: string;
  email: string;
}

export interface IAdminListOfPharmaciesApiParams {
  pharmacistId: string;
  paging: {
    page: number;
  };
}

export interface IAdminPharmacistHistoryBaseApiParams {
  pharmacistId: string;
  pharmacyId: string;
}

export interface IAdminPharmacistHistoryApiParams
  extends IAdminPharmacistHistoryBaseApiParams {
  paging: {
    page: number;
  };
}

export interface IAdminListOfPharmaciesData {
  pharmacyId: string;
  logoId: string;
  location: string;
  city: string;
  favourite: boolean;
  pharmacyName: string;
  totalHours: string;
  totalWorkshift: string;
}

export interface IAdminListOfPharmaciesResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminListOfPharmaciesData>;
}

export interface IAdminPharmacistHistoryData {
  code: string;
  selectedDate: string;
  startDate: string;
  endDate: string;
  bookingId: string;
}

export interface IAdminPharmacistNotesData
  extends IAdminPharmacistHistoryApiParams {
  pharmacistPharmacyNoteId: string;
  note: string;
  createDate: string;
  updateDate: null | string;
}

export interface IAdminPharmacistHistoryResponse
  extends IAdminPharmacistHistoryBaseApiParams {
  data: Array<IAdminPharmacistHistoryData>;
  totalCount: number;
}

export interface IDeactivatedPharmacistListApiParams {
  page: number;
  userText?: string;
}

export interface IActivatePharmacistApiParams {
  pharmacistId: string;
  reason: string;
}

export interface IAdminPharmacistListBaseData {
  pharmacistId: string;
  code: string;
  name: string;
  email: string;
  mobile: string;
  city: string;
  lastActivityDate: string | null;
  deletedDate: string;
  userId: string;
  hourlyRate: number;
  licenseNumber: string;
  watchlistCount: number;
}

export interface IAdminPharmacistListResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminPharmacistListBaseData>;
}
export interface IAdminPharmacyListBaseParams {
  page: number;
  userText?: string;
  softwareId?: string;
  banners?: string;
  contactName?: string;
  city?: string;
}

export interface IAdminPharmacyListBaseData {
  pharmacyId: string;
  logoId: string;
  name: string;
  email: string;
  mobile: string | null;
  city: string;
  contactName: string;
  pharmacyChainId: string;
  softwareId: string;
  lastActivityDate: null | string;
  deletedDate: null | string;
  bannerNumber: string;
  userId: string;
  hourlyRate: number;
  pharmacyHourlyRate: number;
}

export interface IActivePharmacyList extends IGeneralPaginationResponse {
  data: Array<IAdminPharmacyListBaseData>;
}

export interface IAdminCreateWorkshiftModel {
  startDate: string;
  endDate: string;
  recurrenceType: number;
  note: string;
  weekNumber: number | string;
  day: number;
  weekDays: null | number | string;
  month: number;
  emergencyFees?: number;
  mealAllowance?: number;
  travelAllowance?: number;
  paidHourlyRate?: number;
  accommodationAllowance?: number;
  pharmacistHourlyRate?: number;
}

export interface IAdminCreateWorkshiftParams {
  pharmacyId: string;
  model: IAdminCreateWorkshiftModel;
}

export interface IPublishedSoftwareList {
  softwareId: string;
  languageId: string;
  name: string;
  description: string;
}

export interface IAdminListOfPharmacistsApiParams
  extends GenericPaginationAPIParams {
  pharmacyId: string;
}

export interface IAdminListOfPharmacistsData {
  pharmacistId: string;
  role: number;
  totalReplacements: number;
  totalHours: number;
  favourite: boolean;
  isIncompatible: boolean;
  name: string;
  city: string;
}

export interface IAdminListOfPharmacistsResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminListOfPharmacistsData>;
}

export interface IAdminIncompatibleMatchApiParams {
  pharmacyId: string;
  paging: {
    page: number;
  };
}

export interface IAdminIncompatibleMatchData {
  incompatibleMatchId: string;
  pharmacistId: string;
  name: string;
  email: string;
  mobile: string;
  phone: string;
  licenseNumber: string;
  city: string;
  address: string;
}

export interface IAdminIncompatibleMatchResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminIncompatibleMatchData>;
}

export interface IAdminUpdateIncompatibleMatchApiParams {
  pharmacyId: string;
  note: string;
  pharmacistIds: Array<string>;
}

export interface IAdminPharmacyWorkshiftsListAPIParams
  extends GenericPaginationModelAPIParams {
  pharmacyId: string;
  past: boolean;
  status: PharmacyFilterWorkshifts | string;
}

export interface IAdminPharmacyWorkshiftsListData {
  id: string;
  workShiftId: string;
  pharmacyId: string;
  selectedDate: string;
  startDate: string;
  endDate: string;
  totalHours: number;
  totalMinutes: number;
  note: null | string;
  type: ICalendarEventType;
  status: WorkshiftStatus;
  bookingId: null | string;
  booked: boolean;
  code: string;
  pharmacistName: string;
  recurrenceId: null | string;
  replacementType: number;
  confirmationDate: null | string;
  gracePeriodInHours: number;
  bookingState: number;
  canReportIssue: boolean;
  pharmacistId: string;
  pharmacyHourlyRate: number;
  paidHourlyRate: number;
}

export interface IAdminPharmacyWorkshiftsList
  extends IGeneralPaginationResponse {
  data: Array<IAdminPharmacyWorkshiftsListData>;
}

export interface IAdminDeleteWorkshift {
  workshiftIds: Array<string>;
  notes: string;
}

export interface IAdminDeleteSingleWorkshift {
  workshiftId: string;
  notes: string;
}

export interface IAdminDisapprove {
  id: string;
  reason: string;
}

export interface IAdminIncompatibleMatchesSearch {
  pharmacyId: string;
  pharmacies: string;
  pharmacySelected: string;
}

export interface IAdminReportsAPIParams {
  type: ICalendarEventType;
  startDate?: string | null;
  endDate?: string | null;
  replacementType?: number;
  pharmacistId?: string;
  availabilityStatus?: number;
  page: number;
  sortBy: string;
}

export interface IAdminReportsData {
  bookingId: string;
  code: string;
  logoId: string;
  location: string;
  pharmacistHourlyRate: number;
  pharmacyHourlyRate: number;
  startDate: string;
  endDate: string;
  status: WorkshiftStatus;
  selectedDate: string;
  pharmacyName: string;
  replacementType: number;
  pharmacistName: string;
  city: string;
  notes: string;
  gracePeriodInHours: number;
  availabilityStatus: boolean;
  workShiftId: string;
  pharmacyId: string;
  pharmacistId: string;
  availableTimeId: string;
}

export interface IAdminReportsResponse {
  page: number;
  totalCount: number;
  type: string;
  data: Array<IAdminReportsData>;
}

export interface IGenericAdminReportsFilter {
  id: string;
  key: string;
  name: string;
}

export interface IAdminReportsFilter {
  replacementTypes: Array<IGenericAdminReportsFilter>;
  availabilityStatuses: Array<IGenericAdminReportsFilter>;
  sortBy: Array<IGenericAdminReportsFilter>;
}

export interface IAdminReportsPDF {
  pdfContent: string;
}

export interface IAdminListingApiParams {
  userType?: AdminTypes | '';
  userText?: string;
  page: number;
}

export interface IApproveAdminsApiParams {
  userId: string;
  reason: string;
}

export interface IAdminListingResponseData {
  userId: string;
  email: string;
  userName: string;
  userType: AdminTypes;
  lastActivityDate: null | string;
  lastLoginDate: null | string;
}

export interface IAdminListingResponse extends IGeneralPaginationResponse {
  data: Array<IAdminListingResponseData>;
}
export interface IAdminDeletePharmacyChainsAPIParams {
  pharmacyChainId: string;
  reason: string;
}

export interface IAdminDeletePharmacistCapacitiesAPIParams {
  pharmacistCapacityId: string;
  reason: string;
}

export interface IAdminDeleteSoftwaresAPIParams {
  softwareId: string;
  reason: string;
}

export interface ICreateAdminParams {
  email: string;
  userType: AdminTypes;
  username: string;
  languageId: string;
  permissions: Array<string>;
  phone: number;
}

export interface IUpdateAdminParams extends ICreateAdminParams {
  userId: string;
}

export interface IPermissionData {
  permissionId: string;
  type: PermissionsOfAdmin;
  description: string;
}

export interface IPermissionsResponse {
  permissions: Array<IPermissionData>;
}

export interface IAdminDetailsResponse {
  userId: string;
  userName: string;
  email: string;
  userType: AdminTypes;
  languageId: string;
  permissions: Array<{ permissionId: string }>;
  phone: string;
}
export interface IAdminCreateContactPreferenceApiParams {
  name: string;
  published: boolean;
}

export interface IAdminUpdateContactPreferenceApiParams
  extends IAdminCreateContactPreferenceApiParams {
  contactPreferenceId: string;
}

export interface IAdminDeleteContactPreferenceApiParams {
  contactPreferenceId: string;
  reason: string;
}

export interface IAdminPharmacistDetailsSearchApiParams {
  softwareIds?: Array<string>;
  capacityIds?: Array<string>;
  paging: {
    page: number;
  };
}

export interface IAdminPharmacistDetailsSearchData {
  pharmacistId: string;
  firstName: string;
  lastName: string;
  name: string;
  phone: string;
  mobile: string;
  city: string;
  licenseNumber: string;
  capacityId: string;
  capacityTo: number;
  capacityFrom: number;
  softwareId: string;
  software: string;
  expLevel: number;
  email: string;
}

export interface IAdminPharmacistDetailsSearchResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminPharmacistDetailsSearchData>;
}

export interface IAdminPharmacistDetailsSearchFilters {
  pharmacistCapacities: Array<IPharmacistCapacityDetails>;
}

export interface IAdminPharmaciesInfo {
  hourlyRate: number;
  pharmacyHourlyRate: number;
}

export interface IRatingDetailsLevel {
  pharmacistRatingItemId: string;
  level: number;
  percentage: string;
}

export interface IRatingDetailsResponse {
  pharmacistRatingId: string;
  pharmacyName: string;
  rating: string;
  note: string;
  ratingPercentage: string;
  createDate: string;
  pharmacyCode: string;
  logoId: string;
  levels: Array<IRatingDetailsLevel>;
}

export interface IAdminSettings {
  defaultPharmacistRate: number;
  permissions: Array<{ permissionId: string }>;
}
export interface IAdminBroadcastUserGroupsPharmacistApiParams {
  page: number;
  userType: HelpTypes | '';
  city?: string;
  userText?: string;
}

export interface IAdminBroadcastUserGroupsPharmacyApiParams {
  page: number;
  userType: HelpTypes | '';
  city?: string;
  userText?: string;
  softwareIds?: Array<string>;
  banners?: Array<string>;
  weekVolume?: IVolumes;
  weekendVolume?: IVolumes;
}

export interface IAdminBroadcastUserGroupApiData {
  userId: string;
  id: string;
  softwareId: null | string;
  logoId: null | string;
  pharmacyChainId: null | string;
  email: string;
  userName: string;
  userType: HelpTypes;
  mobile: string;
  city: string;
  softwareName: null;
  pharmacistSoftwareIds: null | Array<string>;
  communicationEmail: string;
}

export interface IAdminBroadcastUserGroupResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminBroadcastUserGroupApiData>;
}

export interface IAdminCreateBroadcastGroupApiParams {
  groupName: string;
  userIds: Array<string>;
}

export interface IAdminUpdateBroadcastGroupApiParams {
  groupName: string;
  groupId: string;
  userIds: Array<string>;
}

export interface IAdminBroadcastGroupListApiParams
  extends GenericPaginationModelAPIParams {
  groupName?: string;
}

export interface IAdminBroadcastGroupListData {
  broadcastGroupId: string;
  name: string;
  userId: string;
  userName: string;
  createDate: string;
  updateDate: null | string;
  broadcastGroupUsers: Array<IAdminBroadcastUserGroupApiData>;
}

export interface IAdminBroadcastGroupListResponse
  extends IGeneralPaginationResponse {
  data: Array<IAdminBroadcastGroupListData>;
}

export interface IAdminBroadcastGroupFilter {
  weekVolumes: Array<IVolumes>;
  weekendVolumes: Array<IVolumes>;
}

export interface IBroadcastNotificationsApiParams {
  type?: BroadcastNotificationType;
  page: number;
  startDate?: string | null;
  endDate?: string | null;
  groupIds?: Array<string>;
}

export interface IBroadcastNotificationsData {
  broadcastMessageId: string;
  type: BroadcastNotificationType;
  sentDate: string;
  subject: string;
  message: string;
  broadcastGroups: Array<IAdminBroadcastGroupListData>;
}

export interface IBroadcastNotificationApiResponse
  extends IGeneralPaginationResponse {
  data: Array<IBroadcastNotificationsData>;
}

export interface IAdminSendBroadcastApiParams {
  type: BroadcastNotificationType;
  subject: string;
  message: string;
  groupIds: Array<string>;
}

export interface IAdminLogsGenericParams {
  startDate: string | null;
  endDate: string | null;
  page: number;
  performerText: string;
  userText: string;
  action: LogActionTypes | null | IActionTypeFormik;
  module?: null | ActivityLogModuleTypes;
  pharmacistText?: string;
  pharmacyId?: string;
  pharmacistId?: string;
}

export interface IActionTypeFormik {
  key: LogActionTypes;
  module: ActivityLogModuleTypes;
}

export interface IAdminLogsGenericData {
  code: string;
  startDate: string;
  endDate: string;
  selectedDate: string;
  performerUserId: string;
  performerName: string;
  userPersonnelId: string;
  userName: string;
  pharmacyHourlyRate: number;
  pharmacistHourlyRate: number;
  action: LogActionTypes | null | IActionTypeFormik;
  actionDate: string;
  notes: string;
  isBooked: boolean;
  performerUserType: PerformerTypes;
  userType: PerformerTypes;
  performerPersonnelId: string;
  logId: string;
  isUserActive: boolean;
  isPerformerActive: boolean;
  isPharmacistActive: boolean;
  pharmacistName: string;
  pharmacistId: string;
}

export interface IAdminLogsGenericResponse {
  totalCount: number;
  itemPerPage: number;
  page: number;
  data: Array<IAdminLogsGenericData>;
}

export interface IAdminLogsFilterKeys {
  key: string;
  value: string;
  modules: Array<IAdminLogsFilterKeys>;
}

export interface IGenericMultilevelDropdown extends IAdminLogsFilterKeys {
  modules: Array<IAdminLogsFilterKeys>;
}

export interface IAdminLogsFilters {
  workshiftActions: Array<IAdminLogsFilterKeys>;
  availabilityActions: Array<IAdminLogsFilterKeys>;
  bookingActions: Array<IAdminLogsFilterKeys>;
  activityActions: Array<IGenericMultilevelDropdown>;
}

export interface ILogsUserData {
  userId: string;
  personnelId: string;
  ownerId: null | string;
  email: string;
  userName: string;
  contactName: null | string;
  createdDate: null | string;
  ownerName: null | string;
  approved: boolean;
  userType: PerformerTypes;
  isUserActive: boolean;
}

export interface IActivityLogsData {
  applicationActivityId: string;
  performerUserId: string;
  performerUserType: PerformerTypes;
  performerName: string;
  performerEmail: string;
  performerPersonnelId: string;
  action: ActivityLogActionTypes;
  module: ActivityLogModuleTypes;
  actionDate: string;
  notes: string;
  users: Array<ILogsUserData>;
  isUserActive: boolean;
  affectedCount: number;
  actionText: string;
  showCountOnly: boolean;
}

export interface IActivityLogsResponse {
  totalCount: number;
  itemPerPage: number;
  page: number;
  data: Array<IActivityLogsData>;
}

export interface IDeleteBroadcastGroupApiParams {
  groupId: string;
  reason: string;
}

export interface IDeleteBroadcastNotificationApiParams {
  broadcastNotificationId: string;
  reason: string;
}

export interface IAdminInfo {
  userId: string;
  id: string;
  userName: string;
  code: Language;
  languageId: string;
  phone: string;
  email: string;
}

export interface IUpdateAdminInfo {
  languageId: string;
  phone: string;
  username: string;
}

export interface IAdminPicture {
  contentType: string;
  image: string;
  name?: string;
}

export interface IPharmacyAdminsListingAPIResponse {
  pharmacyCompanyId: string;
  name: string;
  email: string;
  mobile: string;
  active: boolean;
  pharmacies: Array<IPharmacyAdminPharmacies>;
}

export interface IPharmacyAdminDetails {
  pharmacyCompany: {
    user: {
      language: IAllLanguages;
      userId: string;
      email: string;
      userName: string;
      personnelId: string;
      languageId: string;
      culture: string;
      createDate: string;
      timeFormat: TimeFormat;
    };
    pharmacyCompanyId: string;
    appointedPharmacyId: string | null;
    userId: string;
    name: string;
    description: string;
    phone: string;
    mobile: string;
    province: string;
    createDate: string;
    active: boolean;
  };
  pharmacies: Array<IPharmacyAdminPharmacies>;
}

export interface ICreatePharmacyAdmin {
  email: string;
  languageId: string;
  name: string;
  description: string;
  phone: string;
  mobile: string;
  province: string;
  pharmacyIds: Array<string>;
  address: string;
  city: string;
  postalCode: string;
}

export interface IUpdatePharmacyAdmin extends ICreatePharmacyAdmin {
  pharmacyCompanyId: string;
}

export interface IAppointPharmacyParams {
  pharmacyCompany: Omit<ICreatePharmacyAdmin, 'pharmacyIds'>;
  pharmacyId: string;
  pharmacyIds: Array<string>;
}

export interface IAdminPharmacyAdminProfile {
  provinces: Array<IProvinces>;
}

export interface IGetPharmaciesForPharmacyAdminParams {
  userText?: string;
  banners?: string;
  softwareId?: string;
  city?: string;
  page: number;
  contactName?: string;
}

export interface IPharmaciesInPharmacyAdmin {
  pharmacyId: string;
  userId: string;
  logoId: string;
  name: string;
  email: string;
  mobile: string;
  city: string;
  bannerNumber: null;
  contactName: string;
  hourlyRate: number;
  pharmacyHourlyRate: number;
  pharmacyChainId: string;
  softwareId: string;
  lastActivityDate: null;
  deletedDate: null;
  weekVolume: null;
  weekendVolume: null;
}

export interface IPharmaciesInPharmacyAdminResponse
  extends IGeneralPaginationResponse {
  data: Array<IPharmaciesInPharmacyAdmin>;
}

export interface IDeletePharmacyAdminParams {
  pharmacyCompanyId: string;
  reason: string;
}

export interface IBroadcastGroupParams extends GenericPaginationModelAPIParams {
  groupId: string;
}

export interface IBroadcastGroupModel {
  broadcastGroupId: string;
  name: string;
  userId: string;
  userName: string;
  createDate: string;
  updateDate: string | null;
  groupUsersCount: number;
}

export interface IBroadcastGroupData {
  userId: string;
  id: string;
  softwareId: string | null;
  logoId: string;
  pharmacyChainId: string | null;
  email: string;
  userName: string;
  userType: PerformerTypes;
  mobile: string | null;
  city: string | null;
  softwareName: string | null;
}

export interface IPharmacyAdminListApiParams {
  adminText: string;
  pharmacyText: string;
}

export interface IBroadcastGroupResponse extends GenericPaginationResponse {
  group: IBroadcastGroupModel;
  data: Array<IBroadcastGroupData>;
}

export interface IAdminDashboardFilterAPIParams {
  startDate: string | null;
  endDate: string | null;
  startTime?: string | null;
  endTime?: string | null;
  daysInWeek?: string;
  banners?: Array<string>;
}

export interface IAdminMultipleAvailabilityCalendarParams
  extends IAdminCalendarParams {
  pharmacistId: string;
}

export interface IAdminMultipleAvailabilityCalendar extends IAdminCalendar {
  availableTimeId: string;
  isMatchingAvailabilityExist: boolean;
  start: Date;
  end: Date;
  code: string;
  pharmacistName: string;
  title: string;
  bookingState?: BookingState;
  count: number;
  replacementType?: number;
  recurrenceId: string;
  favourite?: boolean;
  pharmacyId: string;
  action?: string;
  isMatchingPrev: boolean;

  // ADDED FROM FRONT-END
  isDeleted: boolean;
}

export interface IAdminPharmacistVacationsDeleteParams {
  vacationIds: Array<string>;
  notes?: string;
}

export interface IPreSubmitDeleteAvailabilities {
  isAvaiabailityRequested: boolean;
}

export interface IAdminStatisticsParams {
  startDate: string;
  endDate: string;
}

export interface IGenericStatisticsResponse {
  month: number;
  data: number;
}

export interface IAdminRegisteredStatistics {
  registeredPharmacies: Array<IGenericStatisticsResponse>;
  registeredPharmacists: Array<IGenericStatisticsResponse>;
  pendigPharmacies: Array<IGenericStatisticsResponse>;
  pendingPharmacists: Array<IGenericStatisticsResponse>;
  approvedPharmacies: Array<IGenericStatisticsResponse>;
  approvedPharmacists: Array<IGenericStatisticsResponse>;
  activePharmacies: Array<IGenericStatisticsResponse>;
  activePharmacists: Array<IGenericStatisticsResponse>;
}

export interface IAdminCancellationStatistics {
  raisedByAdmin: number;
  raisedByPharmacy: number;
  raisedByPharmacist: number;
  pending: number;
  approved: number;
  rejected: number;
}

export interface IAdminFinancialStatistics {
  totalPosted: Array<IGenericStatisticsResponse>;
  totalRequested: Array<IGenericStatisticsResponse>;
  totalBooked: Array<IGenericStatisticsResponse>;
  pharmacyBilling: Array<IGenericStatisticsResponse>;
  pharmacistPayout: Array<IGenericStatisticsResponse>;
  pharmaplanProfit: Array<IGenericStatisticsResponse>;
  hours: Array<IGenericStatisticsResponse>;
  averagePharmacyRate: Array<IGenericStatisticsResponse>;
  averagePharmacistRate: Array<IGenericStatisticsResponse>;
  averageHourlyProfit: Array<IGenericStatisticsResponse>;
}

export interface IAdminWorkshiftStatistics {
  createWorkshift: Array<IGenericStatisticsResponse>;
  updateWorkshift: Array<IGenericStatisticsResponse>;
  deleteWorkshift: Array<IGenericStatisticsResponse>;
  updatePharmacy: Array<IGenericStatisticsResponse>;
  createAvailability: Array<IGenericStatisticsResponse>;
  updateAvailability: Array<IGenericStatisticsResponse>;
  deleteAvailability: Array<IGenericStatisticsResponse>;
  requestedByPharmacist: Array<IGenericStatisticsResponse>;
  updatePharmacist: Array<IGenericStatisticsResponse>;
}

export interface IAdminDailyStatistics {
  pharmacyCount: number;
  pharmacistCount: number;
  postingCount: number;
  bookingRequestCount: number;
  bookingsCount: number;
  cancellationRequestCount: number;
}
