import { FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  adminActions,
  adminBroadcastGroupList,
  adminBroadcastNotifications,
  getBroadcastGroupsList,
  getBroadcastNotifications,
  IAdminBroadcastGroupListData,
  IBroadcastNotificationsData,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { DateTime } from 'luxon';
import {
  BroadcastTabsType,
  Constants,
  MomentTimeFormats,
  ScreenTypes,
} from '../../../helpers/Constants';
import { useAppDispatch } from '../../useAppDispatch';
import {
  broadcastGroupHeaders,
  broadcastGroupRows,
  broadcastNotificationHeaders,
  broadcastNotificationRows,
} from '../../../components/Admin/BroadcastList/helper';
import { useAppSelector } from '../../useAppSelector';
import strings from '../../../localization';
import useBroadcastGroupActions from './useBroadcastGroupActions';
import useAdminPermissions from '../useAdminPermissions';
import useBroadcastNotificationActions from './useBroadcastNotificationActions';
import { renderScreen } from '../../../actions/drawerActions';
import useDrawer from '../../useDrawer';

const { groups, notifications } = BroadcastTabsType;
const {
  getBroadcastList: getBroadcastListAction,
  deleteBroadcastGroup,
  deleteBroadcastNotification,
  getBroadcastNotifications: getBroadcastNotificationAction,
} = adminActions;
const { createBroadcastGroup, editBroadcastGroup, createNotification } = Constants.paths.admin;

const useBroadcastTabs = (tab: BroadcastTabsType) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const { can } = useAdminPermissions();
  const { openDrawer } = useDrawer();

  const { handleDelete } = useBroadcastGroupActions();
  const { handleDelete: notificationDelete, handleEdit: notificationEdit } = useBroadcastNotificationActions();

  const groupListSuccess = useAppSelector((state) =>
    successSelector([getBroadcastListAction], state));
  const notificationListSuccess = useAppSelector((state) =>
    successSelector([getBroadcastNotificationAction], state));

  const groupDeleteSuccess = useAppSelector((state) =>
    successSelector([deleteBroadcastGroup], state));
  const notificationDeleteSuccess = useAppSelector((state) =>
    successSelector([deleteBroadcastNotification], state));

  const groupList = useAppSelector(adminBroadcastGroupList);
  const notificationList = useAppSelector(adminBroadcastNotifications);

  const listConfig = {
    [groups]: groupList,
    [notifications]: notificationList,
  };

  const list = listConfig[tab];
  const { data } = list ?? {};

  const initialValues = {
    [groups]: {
      groupName: '',
    },
    [notifications]: {
      type: '',
      groupIds: [],
      startDate: null,
      endDate: null,
    },
  };

  const showDetails = (broadcastGroupId: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.broadcastList,
          eventId: broadcastGroupId,
        }),
      );
    };

  const handleEdit = (groupId: string, groupName: string) =>
    () => {
      navigate(editBroadcastGroup, { state: { id: groupId, groupName } });
    };

  const groupHandleSubmit = (values: FormikValues, page?: number) => {
    const { groupName } = values ?? {};

    dispatch(
      getBroadcastGroupsList({
        groupName,
        pagingModel: {
          page: page ?? 1,
        },
      }),
    );
  };

  const notificationHandleSubmit = (values: FormikValues, page?: number) => {
    const { startDate, endDate, groupIds, type } = values ?? {};

    dispatch(
      getBroadcastNotifications({
        page: page ?? 1,
        startDate: startDate
          ? (startDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate
          ? (endDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        groupIds,
        type,
      }),
    );
  };

  const onSubmit = {
    [groups]: groupHandleSubmit,
    [notifications]: notificationHandleSubmit,
  };

  const rows = {
    [groups]: broadcastGroupRows(
      data as Array<IAdminBroadcastGroupListData>,
      handleEdit,
      handleDelete,
      can,
      showDetails,
    ),
    [notifications]: broadcastNotificationRows(
      data as Array<IBroadcastNotificationsData>,
      timeFormat,
      notificationEdit,
      notificationDelete,
      can,
    ),
  };

  const headers = {
    [groups]: broadcastGroupHeaders(can),
    [notifications]: broadcastNotificationHeaders(can),
  };

  const success = {
    [groups]: groupListSuccess,
    [notifications]: notificationListSuccess,
  };

  const deleteSuccess = {
    [groups]: groupDeleteSuccess,
    [notifications]: notificationDeleteSuccess,

  };
  const createNewConfig = {
    [groups]: {
      label: strings.createNewGroup,
      onClick: () =>
        navigate(createBroadcastGroup),
    },
    [notifications]: {
      label: strings.createNewNotification,
      onClick: () =>
        navigate(createNotification),
    },
  };

  const baseGroupApi = () => {
    dispatch(getBroadcastGroupsList({ pagingModel: { page: 1 } }));
  };
  const baseNotificationApi = () => {
    dispatch(getBroadcastNotifications({ page: 1 }));
  };

  const baseApi = {
    [groups]: baseGroupApi,
    [notifications]: baseNotificationApi,
  };

  return {
    initialValues: initialValues[tab],
    onSubmit: onSubmit[tab],
    rows: rows[tab],
    headers: headers[tab],
    success: success[tab],
    createNewConfig: createNewConfig[tab],
    list,
    baseApi: baseApi[tab],
    deleteSuccess: deleteSuccess[tab],
  };
};

export default useBroadcastTabs;
