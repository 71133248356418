import React, { useImperativeHandle, useMemo } from 'react';

import { Button, Grid } from '@mui/material';

import DashboardFilter from '@pharmaplan/common/assets/icons/dashboardFilter.svg';

import { activeDate, adminCalendarBanners } from '@pharmaplan/common';

import { DateTime } from 'luxon';
import useAdminDashboardFilter from '../../../../hooks/Admin/useAdminDashboardFilter';
import DateRange from '../../../common/CustomDateRange/DateRange';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import CustomDropdown from '../../../common/CustomDropdown';
import DateTimeRange from '../../../common/DateTimeRange';
import useGetItems from '../../../../hooks/useGetItems';
import strings from '../../../../localization';
import {
  Constants,
  daysDropdown,
  TextFieldVariants,
} from '../../../../helpers/Constants';

import styles from './styles';
import { getDisabledSubmitFilter } from '../../../../selectors/calendarSelector';

interface IAdminDashboardFilter {
  setFilterState: (
    value: React.SetStateAction<{
      submitted: boolean;
      showFilter: boolean;
    }>
  ) => void;
  filterState: {
    submitted: boolean;
    showFilter: boolean;
  };
}

const AdminDashboardFilter = React.forwardRef(
  ({ filterState, setFilterState }: IAdminDashboardFilter, ref) => {
    const disableSubmit = useAppSelector(getDisabledSubmitFilter);
    const { getPharmacyChain } = useGetItems();
    const activeDateSelector = useAppSelector(activeDate);

    const { formik, resetFilter } = useAdminDashboardFilter({
      setFilterState,
      filterState,
    });
    const bannerIds = useAppSelector(adminCalendarBanners);

    const dropdownBanners = useMemo(
      () =>
        bannerIds.map((item) =>
          ({
            key: item,
            label: getPharmacyChain(item),
          })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [bannerIds],
    );

    useImperativeHandle(ref, () =>
      ({
        reset() {
          resetFilter();
        },
        clear() {
          formik.resetForm();
        },
      }));

    const handleNegativeDates = () => {
      formik.setFieldValue('endTime', null);
    };

    const currentMonth = DateTime.fromISO(activeDateSelector);

    return (
      <Grid
        container
        sx={styles.container}
        xs={12}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid item xs={2} sx={{ mr: '12px' }}>
          <CustomDropdown
            menuItems={dropdownBanners}
            formik={formik}
            label={strings.banners}
            name="banners"
            multiple
            imageMenu
          />
        </Grid>
        <Grid item xs={2}>
          <CustomDropdown
            menuItems={daysDropdown()}
            multiple
            formik={formik}
            label={strings.weekSpaceDays}
            name="daysInWeek"
          />
        </Grid>

        <Grid xs={4} container>
          <DateRange
            defaultMonth={currentMonth}
            startName="startDate"
            endName="endDate"
            preventPast={false}
            preventFuture={false}
            formik={formik}
            startMin={currentMonth.startOf('month')}
            startMax={currentMonth.endOf('month')}
            endMax={currentMonth.endOf('month')}
          />
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex' }} gap={1}>
          <DateTimeRange
            allowValidOnly={false}
            removeMargin
            customStartHandler={handleNegativeDates}
            formik={formik}
            horizontal={false}
            variant={TextFieldVariants.outlined}
            startPlaceholder={Constants.timePlaceholder}
            endPlaceholder={Constants.timePlaceholder}
            startNameLabel={strings.startTime}
            endNameLabel={strings.endTime}
            startName="startTime"
            endName="endTime"
          />
        </Grid>
        <Grid sx={styles.submitButtonContainer} item>
          <Button
            variant="contained"
            style={styles.submitButton}
            type="submit"
            disabled={!disableSubmit}
          >
            <img src={DashboardFilter} alt={strings.submit} />
          </Button>
        </Grid>
      </Grid>
    );
  },
);

export default AdminDashboardFilter;
