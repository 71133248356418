import React from 'react';

import { Box, Grid, IconButton } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { SystemStyleObject } from '@mui/system';

import {
  getFilterEventsByType,
  PharmacyLegendVariant,
  setFilterEventsByType,
} from '@pharmaplan/common';

import strings from '../../../localization';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

import classes from './style';

interface IPharmacyLegend {
  variant: `${PharmacyLegendVariant}`;
  totalHrs: number;
  totalCount: number;
  customClass?: Record<string, unknown>;
}

const defaultProps = {
  customClass: {},
};

const PharmacyLegend = ({
  variant,
  totalHrs,
  totalCount,
  customClass,
}: IPharmacyLegend) => {
  const filterByType = useAppSelector(getFilterEventsByType);
  const dispatch = useAppDispatch();

  const onClickTickBox = () => {
    let newTypeArr = [];
    // remove item from eventType arr
    if (filterByType.includes(variant)) {
      newTypeArr = filterByType.filter((eventType) =>
        eventType !== variant);
    } else {
      // add item to eventType arr
      newTypeArr = [...filterByType, variant];
    }
    dispatch(setFilterEventsByType(newTypeArr));
  };

  return (
    <Grid
      container
      flexDirection="row"
      display="flex"
      sx={[classes.mainContainer(variant), customClass as SystemStyleObject]}
    >
      <Box sx={[classes.containerLeftBorder(variant)]} />
      <Grid item xs={7} sx={classes.infoContainer}>
        <Grid>
          <Box component="div" sx={classes.legendTitle}>
            <Box component="span" sx={classes.legendCount}>
              {totalCount}
            </Box>
            <Box component="span">{strings[variant]}</Box>
          </Box>
          <Box component="div" sx={classes.legendInfo}>
            <IconButton sx={classes.timeIcon(variant)}>
              <AccessTime />
            </IconButton>
            <Box component="span" sx={classes.totalHrs}>
              {strings.formatString(strings.hoursWorked, totalHrs)}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={4} sx={classes.tickIconContainer}>
        <Box sx={classes.tickIcon} component="button" onClick={onClickTickBox}>
          {filterByType?.includes(variant) ? <CheckIcon /> : <div />}
        </Box>
      </Grid>
    </Grid>
  );
};

PharmacyLegend.defaultProps = defaultProps;

export default PharmacyLegend;
