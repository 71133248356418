import { useEffect, useMemo, useState } from 'react';
import {
  adminActions,
  AdminLegendVariant,
  getPharmacyDetails,
  getPharmacyWorkshiftsList,
  ICalendarEventType,
  MiscType,
  PermissionsOfAdmin,
  PharmacyFilterWorkshifts,
  PharmacyLegendVariant,
  pharmacyWorkshiftsList,
  resetStatus,
  successOrSelector,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { FormikValues, useFormik } from 'formik';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import {
  pastWorkshiftsListHeaders,
  pharmacyWorkshiftsListHeaders,
  pharmacyWorkshiftsListRow,
} from '../../../components/Admin/PharmacyList/ListOfWorkshifts/helper';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';
import useWorkshiftDelete from '../useWorkshiftDelete';
import useUserProfileLink from '../useUserProfileLink';
import useAdminPermissions from '../useAdminPermissions';
import {
  ListOfWorkshiftsTabTypes,
  OtherScreens,
  ScreenTypes,
} from '../../../helpers/Constants';
import { renderScreen, resetDrawer } from '../../../actions/drawerActions';
import useDrawer from '../../useDrawer';
import useAdminReportsFn from '../../AdminReports/useAdminReportsFn';
import useTableSelectCheckbox from '../../useTableSelectCheckbox';

const { bookAvailablity: bookAvailablityAction, expressBooking } = adminActions;
const { booked, requested, posted } = AdminLegendVariant;

const handleAll = (value: PharmacyFilterWorkshifts | MiscType) =>
  (value === MiscType.All ? '' : value);

const useListOfWorkshifts = (pharmacyId: string) => {
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState<ListOfWorkshiftsTabTypes>(
    ListOfWorkshiftsTabTypes.upcomingWorkshifts,
  );

  const dispatch = useAppDispatch();
  const { goToUserProfile } = useUserProfileLink();
  const { can } = useAdminPermissions();
  const { showWorkshiftDelete, showRequestCancellation, showMultipleWorkshiftsDelete } = useWorkshiftDelete();
  const { openDrawer } = useDrawer();
  const { findMatchingAvailabilities } = useAdminReportsFn();

  const workshifts = useAppSelector(pharmacyWorkshiftsList);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const deleteSuccess = useAppSelector((state) =>
    successOrSelector([adminActions.deletePharmacyWorkshift, adminActions.deleteMultipleWorkshift], state));
  const editSuccess = useAppSelector((state) =>
    successSelector([adminActions.editAdminWorkshift], state));
  const bookingAvailabilitySuccess = useAppSelector((state) =>
    successSelector([bookAvailablityAction], state));
  const expressBookingSuccess = useAppSelector((state) =>
    successSelector([expressBooking], state));

  const canDelete = can(PermissionsOfAdmin.Delete);
  const isPast = selectedTab === ListOfWorkshiftsTabTypes.pastWorkshifts;

  const { data, totalCount } = workshifts ?? {};

  const getWorkshiftList = (selectedPage = 1, status = '', past = isPast) => {
    dispatch(
      getPharmacyWorkshiftsList({
        pharmacyId,
        past,
        status,
        pagingModel: {
          page: selectedPage,
        },
      }),
    );
  };

  const onSubmit = (values: FormikValues) => {
    const { status } = values ?? {};
    const handledStatus = handleAll(status);
    setPage(1);
    getWorkshiftList(1, handledStatus);
  };

  const formik = useFormik({
    initialValues: {
      status: MiscType.All,
    },
    onSubmit,
  });

  const { values, resetForm, handleSubmit } = formik ?? {};
  const { status } = values ?? {};

  const handlePagination = (_: unknown, selectedPage: number) => {
    const gotoPage = selectedPage + 1;
    setPage(gotoPage);
    getWorkshiftList(gotoPage, handleAll(status));
  };

  const showExpressBooking = (workshiftId: string) => {
    openDrawer();
    dispatch(
      renderScreen({
        screenNumber: 5,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workshiftId ?? '',
        workshiftId,
        fromScreen: OtherScreens.ListOfWorkshifts,
        type: posted,
      }),
    );
  };

  const handleEdit = (type: ICalendarEventType, bookingId: string | null, workshiftId: string) =>
    () => {
      openDrawer();

      switch (type) {
        case booked:
          dispatch(
            renderScreen({
              screenNumber: 5,
              screenType: ScreenTypes.availableWorkshift,
              eventId: bookingId ?? '',
              workshiftId,
              type,
              fromScreen: OtherScreens.ListOfWorkshifts,
            }),
          );
          return;
        case requested:
          showExpressBooking(workshiftId);
          return;
        case posted:
          dispatch(
            renderScreen({
              screenNumber: 2,
              screenType: ScreenTypes.availableWorkshift,
              eventId: workshiftId,
              fromScreen: OtherScreens.ListOfWorkshifts,
            }),
          );
          break;
        default:
          break;
      }
    };

  const selectableData = useMemo(
    () => {
      const skipCriteria = [PharmacyLegendVariant.booked, PharmacyLegendVariant.requested];
      return data.filter((item) =>
        !skipCriteria.includes(item.type as PharmacyLegendVariant)).map((item) =>
        item.workShiftId);
    },
    [data],
  );

  const {
    handlePress,
    selectedIds,
    selectAll,
    allIds,
    handleSelectedIds,
    handleSelectAll,
    handleReset,
  } = useTableSelectCheckbox({
    page,
    dataLength: selectableData.length,
    useOfEdit: false,
    userType: MiscType.All,
  });

  const handleWorkshiftDelete = () => {
    showMultipleWorkshiftsDelete(allIds);
  };

  const customSelectAll = (toBeSet: boolean) => {
    let ids: Array<string> = [];

    if (toBeSet) {
      ids = selectableData;
      handleSelectedIds({ [page]: ids });
    } else {
      handleSelectedIds({ [page]: [] });
    }

    handleSelectAll({ [page]: toBeSet });
  };

  const table: IDynamicTableObject = {
    headerBar: [],
    title: strings.activePharmacies,
    headers: isPast
      ? pastWorkshiftsListHeaders()
      : pharmacyWorkshiftsListHeaders(),
    rows: pharmacyWorkshiftsListRow({
      data,
      timeFormat,
      goToUserProfile,
      showWorkshiftDelete,
      findMatchingAvailabilities,
      canDelete,
      isPast,
      showRequestCancellation,
      handleEdit,
      showExpressBooking,
      selectedItems: selectedIds,
      handlePress,
    }),
  };

  useEffect(() => {
    dispatch(getPharmacyDetails(pharmacyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSubmit();
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(resetStatus([adminActions.deletePharmacyWorkshift, adminActions.deleteMultipleWorkshift]));
      showSuccess(dispatch, strings.workshiftDeletedSuccessfully);
      resetForm();
      getWorkshiftList();
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  useEffect(() => {
    if (bookingAvailabilitySuccess || expressBookingSuccess) {
      getWorkshiftList();
      dispatch(resetStatus([bookAvailablityAction, expressBooking]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingAvailabilitySuccess, expressBookingSuccess]);

  useEffect(() => {
    if (editSuccess) {
      dispatch(resetStatus([adminActions.editAdminWorkshift]));
      showSuccess(dispatch, strings.updatedSuccessfullyFormat);
      resetForm();
      getWorkshiftList();
      dispatch(resetDrawer());
    }
  });

  return {
    table,
    page,
    handlePagination,
    totalCount,
    selectedTab,
    setSelectedTab,
    formik,
    selectAll,
    selectAllFunc: customSelectAll,
    handleWorkshiftDelete,
    selectableData,
  };
};

export default useListOfWorkshifts;
