import { useEffect, useMemo, useState } from 'react';

import { adminResetValue, HelpTypes, MiscType } from '@pharmaplan/common';

import { useAppDispatch } from './useAppDispatch';

type ISelectAllFunc<T, key extends keyof T> = {
  data: Array<T>;
  idProperty: key;
};

interface IUseTableSelectCheckbox {
  useOfEdit: false;
  page: number;
  nameOfData?: string;
  nameOfEdit?: string;
  userType: MiscType | HelpTypes | string;
  dataLength: number;
}

interface IUseTableSelectWithEdit {
  useOfEdit: true;
  // NAME OF DATA PROPERTY IN REDUCER
  nameOfData: string;
  // NAME OF UPDATE DATA PROPERTY IN REDUCER
  nameOfEdit: string;
  page: number;
  userType: MiscType | HelpTypes | string;
  dataLength: number;
}

type numberBooleanObj = { [key: number]: boolean };
type numberStringObj = { [key: number]: Array<string> | undefined };

type ISelectAll = {
  [userType: string]: numberBooleanObj;
};

export type ISelectedIds = {
  [userType: string]: numberStringObj;
};

export type IIsAlreadyIndexed = {
  [userType: string]: numberBooleanObj;
};

const useTableSelectCheckbox = ({
  page,
  userType,
  nameOfData,
  nameOfEdit,
  useOfEdit,
  dataLength,
}: IUseTableSelectCheckbox | IUseTableSelectWithEdit) => {
  const dispatch = useAppDispatch();

  const [idsAlreadyThere, setIdsAlreadyThere] = useState<{
    editIds: Array<string>;
    removedIds: Array<string>;
  }>({ editIds: [], removedIds: [] });
  const [selectAll, setSelectAll] = useState<ISelectAll>({});
  const [selectedIds, setSelectedIds] = useState<ISelectedIds>({});
  const [isAlreadyIndexed, setIsAlreadyIndexed] = useState<IIsAlreadyIndexed>(
    {},
  );

  const handleSelectedIds = (obj: numberStringObj) => {
    setSelectedIds((prev) =>
      ({
        ...prev,
        [userType]: { ...prev?.[userType], ...obj },
      }));
  };

  const handleSelectAll = (obj: numberBooleanObj) => {
    setSelectAll((prev) =>
      ({
        ...prev,
        [userType]: { ...prev?.[userType], ...obj },
      }));
  };

  const handlePress = (id: string) =>
    () => {
      const currentIds = selectedIds?.[userType]?.[page] ?? [];
      if (idsAlreadyThere.editIds.includes(id)) {
        setIdsAlreadyThere((prev) =>
          ({
            editIds: prev.editIds.filter((item) =>
              item !== id),
            removedIds: [...prev.removedIds, id],
          }));
      }
      if (currentIds?.includes(id)) {
        handleSelectedIds({ [page]: currentIds?.filter((item) =>
          item !== id) });
      } else {
        handleSelectedIds({ [page]: [...currentIds, id] });
      }
    };

  const selectAllFunc = <T, key extends keyof T>({ data, idProperty }: ISelectAllFunc<T, key>) =>
    (toBeSet: boolean) => {
      let allIds: Array<string> = [];
      allIds = data.map((item) =>
        item[idProperty]) as Array<string>;
      if (toBeSet) {
        handleSelectedIds({ [page]: allIds });
      } else {
        handleSelectedIds({ [page]: [] });
      }

      handleSelectAll({ [page]: toBeSet });
    };

  const handleEdit = <T, key extends keyof T>({ data, idProperty }: ISelectAllFunc<T, key>) =>
    (editIds: Array<string>) => {
      if (isAlreadyIndexed?.[userType]?.[page]) {
        return;
      }
      const matchingIds = data
        .filter((item) =>
          editIds.includes(item[idProperty] as string))
        .map((item) =>
          item[idProperty]) as Array<string>;
      handleSelectedIds({ [page]: matchingIds });

      setIsAlreadyIndexed((prev) =>
        ({
          ...prev,
          [userType]: {
            ...prev?.[userType],
            [page]: !!matchingIds.length,
          },
        }));
    };

  useEffect(
    () =>
      () => {
        if (useOfEdit) {
          dispatch(adminResetValue([nameOfData, nameOfEdit]));
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    handleSelectAll({
      [page]: selectedIds[userType]?.[page]?.length === dataLength,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const allIds = useMemo(() => {
    let res: any[] = [];
    Object.keys(selectedIds).forEach((key) => {
      res = [
        ...new Set([
          ...res,
          ...(Object.values(selectedIds?.[key] ?? {}).flat() as Array<string>),
        ]),
      ];
      return res;
    });

    return res;
  }, [selectedIds]);

  const handleReset = () => {
    setSelectAll({});
    setSelectedIds({});
    setIsAlreadyIndexed({});
  };

  return {
    handlePress,
    selectAllFunc,
    selectedIds: selectedIds?.[userType]?.[page] ?? [],
    setSelectedIds,
    selectAll: !!selectAll?.[userType]?.[page],
    setSelectAll,
    handleEdit,
    allIds: allIds.filter((item) =>
      !idsAlreadyThere.removedIds.includes(item)),
    handleReset,
    handleSelectedIds,
    setIdsAlreadyThere,
    setIsAlreadyIndexed,
    handleSelectAll,
  };
};

export default useTableSelectCheckbox;
