import { ICalendarEventType, TypeOfUser } from '@pharmaplan/common';
import ThemeConstants from '../../../../theme/ThemeConstants';

const {
  common: { deletedAvails },
} = ThemeConstants;

const classes = {
  eventContainer: (
    eventType: ICalendarEventType,
    style: (React.CSSProperties & { xOffset: number }) | undefined,
    typeOfUser: TypeOfUser,
    isDeleted?: boolean,
  ) =>
    ({
      display: 'flex',
      flexDirection: 'column',
      borderLeftStyle: 'solid',
      borderLeftWidth: '3px',
      justifyContent: 'space-between',
      minHeight: '22px',
      borderLeftColor: isDeleted ? deletedAvails : `${typeOfUser}.${eventType}`,
      backgroundColor: isDeleted ? '#ffadad' : `${typeOfUser}.${eventType}Light`,
      borderRadius: '0px 5px 5px 0px',
      cursor: 'pointer',
      // style only comes in the week view
      ...(style
        ? {
          height: `${style?.height}`,
          width: `${style?.width}`,
          top: `${style?.top}%`,
          left: `${style?.left}%`,
          position: 'absolute',
        }
        : {}),
    }),
  eventDataContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  tooltipContainer: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
  ) =>
    ({
      backgroundColor: `${typeOfUser}.${eventType}Light`,
    }),

  gridContainer: { flex: 1, alignItems: 'center' },

  eventTitle: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
    isDeleted: boolean,
  ) =>
    ({
      color: isDeleted ? deletedAvails : `${typeOfUser}.${eventType}`,
      fontWeight: 500,
      fontSize: '11px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginLeft: '6px',
      overflow: 'hidden',
    }),

  eventTime: (eventType: ICalendarEventType, typeOfUser: TypeOfUser) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      fontWeight: 500,
      fontSize: '12px',
      marginLeft: '0.3rem',
      display: 'flex',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
  rightGrid: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  rightBlock: {
    display: 'flex',
    backgroundColor: 'white',
    height: 15,
    width: 15,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textColor: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
    isDeleted: boolean,
  ) =>
    ({
      color: isDeleted ? deletedAvails : `${typeOfUser}.${eventType}`,
      fontWeight: '600',
      fontSize: 9,
      textAlign: 'center',
    }),
  eventClockIconContainer: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
  ) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
      '& svg': {
        maxHeight: '15px',
        maxWidth: '15px',
      },
    }),
  paperPopover: {
    width: 550,
  },
  titleContainer: {
    overflow: 'hidden',
  },
  bellContainer: {
    marginLeft: '3px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },

  waving: {
    '@keyframes waving': {
      '0%': { transform: 'rotate(0.0deg)' },
      '15%': { transform: 'rotate(14.0deg)' },
      '30%': { transform: 'rotate(-8.0deg)' },
      '40%': { transform: 'rotate(14.0deg)' },
      '50%': { transform: 'rotate(-4.0deg)' },
      '60%': { transform: 'rotate(10.0deg)' },
      '70%': { transform: 'rotate(0.0deg)' },
      '100%': { transform: 'rotate(0.0deg)' },
    },
    animation: 'waving 2.5s infinite ease',
  },
};

export default classes;
