import {
  adminActions,
  notificationActions,
  pharmacistActions,
  pharmacyActions,
  userActions,
} from '../actions';
import actionTrackerList from './actionTrackerList';

const filterItems = [
  pharmacistActions.getEvent,
  pharmacistActions.getAvailability,
  pharmacyActions.getBookedPharmacistDetails,
  pharmacyActions.addFavourite,
  pharmacyActions.removeFavourite,
  pharmacistActions.addFavourite,
  pharmacistActions.removeFavourite,
  pharmacistActions.getPostedShifts,
  pharmacistActions.watchlist,
  pharmacistActions.deleteWatchlist,
  adminActions.adminPharmacistPictures,
  adminActions.getActiveUsers,
  userActions.logos,
  adminActions.getAdminProfile,
  adminActions.getAdminSettings,
  adminActions.getAdminInfo,
];
const filteredActionTracker = actionTrackerList.filter(
  (item) => !filterItems.includes(item)
);

const actionTracker = [
  ...filteredActionTracker,
  notificationActions.getNotification,
  notificationActions.getNotificationsListPagination,
  notificationActions.markAsRead,
  pharmacistActions.incomeReports,
  pharmacistActions.incomeDetails,
];

export default actionTracker;
