import React, { useState } from 'react';

import {
  MenuList,
  Paper,
  MenuItem,
  Box,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';

import {
  getTypeOfUser,
  isUserAvatar,
  language,
  profileImage,
  ReleaseDate,
  toType,
  TypeOfUser,
} from '@pharmaplan/common';
import CustomAvatar from '../../common/CustomAvatar';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useUser from '../../../hooks/useUser';
import ThemeConstants from '../../../theme/ThemeConstants';
import { getDayMonthDateYear } from '../../Reports/helpers';
import strings from '../../../localization';

import { settingsList } from './helpers';
import classes from './style';
import { Constants, profileTypes } from '../../../helpers/Constants';
import FullScreenImage from '../../common/FullScreenImage';
import useAdmin from '../../../hooks/useAdmin';

interface IProfileSettings {
  onClick: (prop: string) => void;
  selected: string;
}

const ProfileSettings = ({ onClick, selected }: IProfileSettings) => {
  const list = settingsList();
  const { name } = useUser();

  const avatar = useAppSelector(profileImage);
  const userType = useAppSelector(getTypeOfUser);
  const isAvatar = useAppSelector(isUserAvatar);
  const userLang = useAppSelector(language);

  const [showImage, setShowImage] = useState(false);

  const isPharmacy = userType === TypeOfUser.pharmacy;

  const {
    paths: { staticPages },
  } = Constants;

  const releaseDateToISO = new Date(ReleaseDate.web).toISOString();
  const releaseDate = getDayMonthDateYear(releaseDateToISO);

  const { aboutUs, privacyPolicy } = staticPages[userLang] ?? {};
  const { isAdmin } = useAdmin();

  const fillCondition = (id: string) => {
    const isCoordinates = id === profileTypes.coordinates;
    const isGeneral = id === profileTypes.general;
    const isContacts = id === profileTypes.contacts;

    const myLocationOnMap = selected === profileTypes.myLocationOnMap;
    const geographicAvailability = selected === profileTypes.geographicAvailability;

    const isSelected = selected === id;

    switch (true) {
      case isSelected:
        return true;
      case isCoordinates || isContacts:
        return myLocationOnMap;
      case isGeneral:
        return geographicAvailability;
      default:
        return false;
    }
  };

  const handleOnClick = (id: string) =>
    () => {
      if (id === profileTypes.aboutUs) {
        window.open(aboutUs, '_blank');
      } else if (id === profileTypes.privacyPolicy) {
        window.open(privacyPolicy, '_blank');
      } else {
        onClick(id);
      }
    };

  const showHide = (val: boolean) =>
    () => {
      if (isAdmin || !(isPharmacy || !isAvatar)) {
        setShowImage(val);
      }
    };

  return (
    <>
      {showImage && (
        <FullScreenImage image={avatar} hideImage={showHide(false)} />
      )}
      <Box component="div" className="ProfileSettings-container">
        <Box component={Paper}>
          <Box component="div" sx={classes.avatarContainer}>
            <CustomAvatar showFullImage={showHide(true)} />

            <Box sx={classes.settingsUserTitle} component="div">
              <Typography sx={classes.userTitle}>
                {strings.formatString(strings.welcomeUser, name)}
              </Typography>
            </Box>

            <Typography sx={classes.settingsUserTitleInfo}>
              {strings.manageYourInfo}
            </Typography>
          </Box>

          <MenuList>
            {list[toType<typeof list>(userType)]?.map((setting) => {
              const { id, icon: Icon, title } = setting ?? {};

              const iconFill = fillCondition(id)
                ? ThemeConstants.primary.main
                : ThemeConstants.grey[500];

              return (
                <MenuItem
                  key={id}
                  onClick={handleOnClick(id)}
                  selected={fillCondition(id)}
                  sx={classes.menuItem}
                >
                  <ListItemIcon>
                    <Icon fill={iconFill} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ style: classes.ellipsize }}
                  >
                    {title}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </Box>

        <Box sx={classes.detailsContainer} component="div">
          <Typography sx={classes.detailsText}>
            {`${strings.version}: ${process.env.REACT_APP_VERSION}`}
          </Typography>

          <Typography sx={classes.detailsText}>
            {`${strings.releaseDate}: ${releaseDate}`}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ProfileSettings;
