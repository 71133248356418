import React, { FC } from 'react';

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Container,
  Divider,
  IconButton,
  PopoverOrigin,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as MenuIcon } from '@pharmaplan/common/assets/icons/menuIcon.svg';
import {
  TypeOfUser,
  getTypeOfUser,
  setUserType,
  isPharmacyAdminLoginSelector,
} from '@pharmaplan/common';
import CustomPopover from '../common/CustomModal/CustomPopover';
import CustomMenuList from '../common/CustomMenuList';

import { ReactComponent as BellIcon } from '../../assets/svg/bellIcon.svg';
import { ReactComponent as SwitchIcon } from '../../assets/svg/switchIcon.svg';

import useUserVariantsNav from '../../hooks/useUserVariantsNav';
import { useAppSelector } from '../../hooks/useAppSelector';
import useNavbar from '../../hooks/navbar/useNavbar';
import { Constants } from '../../helpers/Constants';
import CustomButton from '../common/CustomButton';
import useAdmin from '../../hooks/useAdmin';
import strings from '../../localization';

import Notifications from './Notifications/Notifications';
import NavbarLogo from './NavbarLogo';
import classes from './style';

const { profile } = Constants.paths.pharmacyAdmin;

interface INavbar {
  UpperToolbar?: React.JSX.Element;
  BottomToolbar: React.JSX.Element;
}

const {
  rightNavbarContainer,
  navbarContainer,
  navbarAvatar,
  mainSubContainer,
  navbarToolbar,
  navbarUserTitle,
  navbarMenuContainer,
  welcomeText,
  navLogo,
  customBadge,
  notificationWidth,
} = classes;

const anchor = { horizontal: 'center', vertical: 'center' };

const Navbar: FC<INavbar> = ({ UpperToolbar, BottomToolbar }) => {
  const {
    handleNotificationClick,
    notificationCount,
    isNotificationOpen,
    handleNotificationClose,
    notifications,
    navigate,
    dispatch,
    name,
    avatar,
  } = useNavbar();
  const { isAdmin } = useAdmin();
  const { pathname } = useLocation();

  const userType = useAppSelector(getTypeOfUser);

  const isPharmacy = userType === TypeOfUser.pharmacy;
  const isPharmacyAdmin = userType === TypeOfUser.pharmacyAdmin;
  const isPharmacyAdminLogin = useAppSelector(isPharmacyAdminLoginSelector);

  const { homePath, items } = useUserVariantsNav();

  const imgProps = isPharmacy || isPharmacyAdminLogin
    ? classes.avatarContain
    : classes.avatarCover;

  const isPharmacyAdminProfile = pathname === profile;
  const showSwitchPharmacy = (isPharmacy && isPharmacyAdminLogin) || isPharmacyAdminProfile;
  const showBellIcon = !isAdmin && !isPharmacyAdmin;

  const switchPharmacy = () => {
    dispatch(setUserType(TypeOfUser.pharmacyAdmin));
    navigate(Constants.paths.pharmacyAdmin.home, {
      replace: !isPharmacyAdminProfile,
    });
  };

  return (
    <AppBar sx={navbarContainer} color="secondary" position="static">
      <Container maxWidth="xl" sx={mainSubContainer}>
        <Toolbar sx={navbarToolbar} disableGutters>
          <Link to={homePath} style={navLogo}>
            <NavbarLogo />
          </Link>
          {UpperToolbar}
          {/* Menus Container  */}
          <Box sx={navbarMenuContainer}>
            {showSwitchPharmacy && (
              <>
                <CustomButton
                  startIcon={<SwitchIcon />}
                  customClass={classes.switchPharmacy}
                  customButtonStyle={classes.switchPharmacyText}
                  variant={Constants.variant.text}
                  onClick={switchPharmacy}
                  label={strings.switchPharmacy}
                />
                <Divider sx={classes.divider} />
              </>
            )}
            {/* Notifications */}
            {showBellIcon && (
              <Box sx={rightNavbarContainer}>
                <IconButton onClick={handleNotificationClick}>
                  <Badge sx={customBadge} badgeContent={notificationCount}>
                    <BellIcon />
                  </Badge>
                </IconButton>
                <CustomPopover
                  hasTitle={false}
                  open={isNotificationOpen}
                  onClose={handleNotificationClose}
                  anchorEl={notifications}
                  customWidth={notificationWidth}
                >
                  <Box onClick={handleNotificationClose}>
                    <Notifications
                      closeNotification={handleNotificationClose}
                    />
                  </Box>
                </CustomPopover>
              </Box>
            )}

            {/* Avatar Menu */}
            <Box sx={classes.rightNavbarContainer}>
              {name && (
                <Box sx={navbarUserTitle} component="span">
                  <Typography sx={welcomeText}>
                    {strings?.formatString(strings.welcomeUser, name)}
                  </Typography>
                </Box>
              )}
              <Avatar
                id="avatarGuide"
                sx={navbarAvatar}
                alt="User "
                imgProps={{ style: imgProps }}
                src={avatar}
              />
              <IconButton size="small">
                <CustomMenuList
                  margin={10}
                  items={items}
                  anchorOrigin={anchor as PopoverOrigin}
                  keepMounted
                >
                  <MenuIcon />
                </CustomMenuList>
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
        {/* CUSTOM TOOLBAR */}
        {BottomToolbar}
      </Container>
    </AppBar>
  );
};

export default Navbar;
