import React from 'react';
import {
  AllowanceTypes,
  BookingState,
  ICalendarEventType,
  PharmacistLegendVariant,
  ServiceTypes,
  TypeOfBill,
  acceptUpdatedTimings,
  approveRequest,
  cancelBooking,
  cancelRequestedBooking,
  counterOffer,
  getTypeOfUser,
  pharmacistActions,
  pharmacistEvent,
  rejectUpdatedTimings,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';

import { FormikValues } from 'formik';
import useCancellationBooking from '../useCancellationBooking';
import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import BillDetails from '../../components/SelfService/BillDetails';
import { renderScreen, setCurrent } from '../../actions/drawerActions';
import { ScreenTypes } from '../../helpers/Constants';
import classes from '../../components/SelfService/ServiceWorkshiftDetails/styles';
import strings from '../../localization';
import usePreRequest from '../usePreRequest';
import useServiceWorkshiftDetailsModals from './useServiceWorkshiftDetailsModals';
import { selectFooterPaginationCurrent } from '../../selectors/drawerSelector';

const { None, PendingWithPharmacist, UpdatedTiming } = BookingState;
const { getEvent } = pharmacistActions;
const { requested, booked, workshift } = PharmacistLegendVariant;
const { counterOffer: counterOfferBill, finalBill } = TypeOfBill;
const { custom: customAllowanceType } = AllowanceTypes;
const { self, full } = ServiceTypes;

const { cancelButton, postedButton } = classes;

const useServiceWorkshiftDetails = (
  id: string,
  eventType: ICalendarEventType,
) => {
  const { handleSelfCancellation, showCancelConfirmation } = useCancellationBooking();
  const { preRequestBooking } = usePreRequest();
  const { showPostedList, confirmAction } = useServiceWorkshiftDetailsModals();

  const serviceType = ServiceHandler.getService();
  const dispatch = useAppDispatch();

  const event = useAppSelector(pharmacistEvent);
  const counterOfferEvent = useAppSelector(counterOffer);
  const userType = useAppSelector(getTypeOfUser);
  const current = useAppSelector(selectFooterPaginationCurrent);

  const {
    counterOfferId,
    bookingState,
    startDate = '',
    confirmationDate = '',
    gracePeriodInHours = 0,
    count = 0,
  } = event ?? {};

  const isCounterOffer = counterOfferId && bookingState === None;
  const isBooked = eventType === booked;
  const isWorkshift = eventType === workshift;

  const isSelfWorkshift = isWorkshift && serviceType === self;

  const billTypeConfig = isCounterOffer ? counterOfferBill : finalBill;
  const eventConfig = isCounterOffer ? counterOfferEvent : event;

  const successAction = isWorkshift ? pharmacistActions.getPharmacistWorkshiftDetails : pharmacistActions.getEvent;

  const fullEvent = {
    ...event,
    accommodationAllowanceType: customAllowanceType,
    travelAllowanceType: customAllowanceType,
    mealAllowanceType: customAllowanceType,
  };

  const handleFulLServiceCancellation = (values: FormikValues) => {
    const { reason } = values ?? {};
    dispatch(cancelBooking({ bookingid: id, reason }));
  };

  const selfServiceCancelBooking = () => {
    handleSelfCancellation(startDate, confirmationDate, id, gracePeriodInHours);
  };

  const fullServiceCancelBooking = () => {
    showCancelConfirmation(id, handleFulLServiceCancellation);
  };

  const disablePostedBtn = count === 0;

  const postedWorkShift = strings.formatString(
    strings.viewPostedWorkshifts,
    count,
  );

  const serviceConfig = {
    [self]: {
      cancelBooking: selfServiceCancelBooking,
      billDetails: (
        <BillDetails
          successAction={getEvent}
          billType={billTypeConfig}
          event={eventConfig}
          isCounterOffer={!!isCounterOffer}
        />
      ),
    },
    [full]: {
      cancelBooking: fullServiceCancelBooking,
      billDetails: (
        <BillDetails
          successAction={successAction}
          billType={finalBill}
          event={fullEvent}
        />
      ),
    },
  };

  const { cancelBooking: handleCancelBooking } = serviceConfig[serviceType];

  const goToCounterOffer = (eventIds: Array<string>) => {
    dispatch(setCurrent(current));

    dispatch(
      renderScreen({
        eventIds,
        eventId: id,
        type: eventType,
        screenType: ScreenTypes.serviceWorkshiftDetails,
        screenNumber: 2,
      }),
    );
  };

  const handleCancelRequestBooking = () => {
    dispatch(cancelRequestedBooking(id));
  };

  const handleConfirmAction = () => {
    confirmAction(id);
  };

  const handleApproveRequest = () => {
    dispatch(approveRequest({ userType, bookingId: id }));
  };

  const handleAcceptUpdatedTimings = () => {
    dispatch(acceptUpdatedTimings(id));
  };

  const handleRejectUpdatedTimings = () => {
    dispatch(rejectUpdatedTimings(id));
  };

  const handlePreRequestBooking = () => {
    preRequestBooking(id);
  };

  const bookingStateConfig = () => {
    const bookState = bookingState ?? None;

    const defaultButtons = {
      primaryButton: {
        label: postedWorkShift as string,
        onClick: showPostedList,
      },
      secondaryButton: {
        label: strings.cancelRequest,
        onClick: handleCancelRequestBooking,
      },
    };

    switch (bookState) {
      case None:
        if (isBooked) {
          return {
            secondaryButton: {
              label: strings.cancelBooking,
              customButtonStyle: cancelButton,
              onClick: handleCancelBooking,
            },
            primaryButton: { label: '', onClick: null },
          };
        }
        return {
          ...defaultButtons,
          primaryButton: {
            ...defaultButtons.primaryButton,
            variant: 'text' as const,
            disabled: disablePostedBtn,
            customButtonStyle: postedButton,
          },
        };

      case PendingWithPharmacist:
        return {
          primaryButton: {
            label: strings.confirm,
            onClick: handleApproveRequest,
          },
          secondaryButton: {
            label: strings.reject,
            onClick: handleConfirmAction,
          },
        };

      case UpdatedTiming:
        if (isBooked) {
          return {
            primaryButton: {
              label: strings.approveUpdates,
              onClick: handleAcceptUpdatedTimings,
            },
            secondaryButton: {
              label: strings.declineUpdates,
              onClick: handleRejectUpdatedTimings,
            },
          };
        }
        return {
          primaryButton: { label: '', onClick: null },
          secondaryButton: {
            label: strings.declineUpdates,
            onClick: null,
          },
        };

      default:
        return defaultButtons;
    }
  };

  const buttonConfig = {
    [workshift]: {
      primaryButton: {
        label: strings.requestBooking,
        onClick: handlePreRequestBooking,
      },
      secondaryButton: {
        label: postedWorkShift as string,
        customButtonStyle: postedButton,
        disabled: disablePostedBtn,
        onClick: showPostedList,
      },
    },
    [requested]: bookingStateConfig(),
    [booked]: bookingStateConfig(),
  };

  return {
    serviceConfig,
    goToCounterOffer,
    buttonConfig,
    isCounterOffer,
    isBooked,
    isWorkshift,
    isSelfWorkshift,
  };
};

export default useServiceWorkshiftDetails;
