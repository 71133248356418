import {
  HelpTypes,
  HelpUserTypes,
  IAdminBroadcastUserGroupApiData,
  IPublishedSoftwareList,
  MiscType,
} from '@pharmaplan/common';
import {
  Constants,
  DynamicTableCell,
  noDataPlaceholder,
  OtherScreens,
  UserTypesDropdown,
} from '../../../../helpers/Constants';
import { IUserProfileLinkSignature } from '../../../../hooks/Admin/useUserProfileLink';
import strings from '../../../../localization';
import IDropdownObject from '../../../../types/ICustomDropdown';
import styles from './styles';

const { text, icon, checkbox, customLink } = DynamicTableCell;
const { pharmacist, pharmacy, pharmacyAdmin } = HelpTypes;
const { dropdown, text: textField } = Constants.formInputType;

const headerKeys = {
  banner: 'banner',
  weekVolume: 'weekVolume',
  weekendVolume: 'weekendVolume',
  software: 'software',
};
const { banner, weekVolume, weekendVolume, software } = headerKeys;
const itemsToRemove = [banner];
const itemsToRemovePharmacyAdmin = [banner, software];

interface ICreateBroadcastGroupRows {
  data: Array<IAdminBroadcastUserGroupApiData>;
  getImage: (id: string) => string;
  handlePress: (id: string) => () => void;
  selectedItems?: Array<string>;
  userType: string;
  goToUserProfile: IUserProfileLinkSignature;
  publishedSoftwares: Array<IPublishedSoftwareList>;
}

export const createBroadcastGroupHeaders = (userType: string) => {
  const isPharmacist = userType === HelpTypes.pharmacist;
  const isPharmacyAdmin = userType === HelpTypes.pharmacyAdmin;

  const arr = [
    { key: banner, label: strings.banner },
    { key: '2', label: strings.name },
    { key: '3', label: strings.userType },
    { key: '4', label: strings.mobile },
    { key: '5', label: strings.email },
    { key: '6', label: strings.area },
    { key: software, label: strings.software },
    { key: '8', label: strings.action },
  ];

  if (isPharmacist) {
    return arr.filter((item) =>
      !itemsToRemove.includes(item.key));
  }

  if (isPharmacyAdmin) {
    return arr.filter((item) =>
      !itemsToRemovePharmacyAdmin.includes(item.key));
  }
  return arr;
};

export const createBroadcastGroupRows = ({
  data,
  getImage,
  handlePress,
  selectedItems,
  userType: outUserType,
  goToUserProfile,
  publishedSoftwares,
}: ICreateBroadcastGroupRows) =>
  data?.map((item) => {
    const {
      id,
      logoId,
      userName,
      userType,
      mobile,
      email,
      city,
      softwareName,
      pharmacistSoftwareIds,
      userId,
    } = item;

    const isUserPharmacy = `${userType}` === HelpTypes.pharmacy;
    const isPharmacist = outUserType === HelpTypes.pharmacist;
    const isPharmacyAdmin = outUserType === HelpTypes.pharmacyAdmin;

    const userTypeConfig = {
      [pharmacy]: strings.pharmacy,
      [pharmacist]: strings.pharmacist,
      [pharmacyAdmin]: strings.pharmacyAdministrator,
    };

    const userTypeString = userTypeConfig[outUserType as keyof typeof userTypeConfig];

    const pharmacistSoftwaresList = publishedSoftwares
      .filter((pItem) =>
        pharmacistSoftwareIds?.includes(pItem.softwareId))
      .map((pItem) =>
        pItem.name)
      .join(', ');

    const image = getImage(logoId ?? '');
    let renderingData = [
      {
        key: banner,
        value: isUserPharmacy ? image : '-',
        type: isUserPharmacy ? icon : text,
      },
      {
        key: '2',
        value: {
          label: userName,
          handlePress: goToUserProfile({
            personnelId: id,
            userType: HelpUserTypes[userType as keyof typeof HelpUserTypes],
            fromScreen: OtherScreens.BroadcastGroups,
          }),
        },
        type: customLink,
      },
      { key: '3', value: userTypeString, type: text },
      { key: '4', value: mobile || noDataPlaceholder, type: text },
      { key: '5', value: email, type: text },
      { key: '6', value: city, type: text },
      {
        key: software,
        value: isUserPharmacy ? softwareName : pharmacistSoftwaresList,
        type: text,
      },
      {
        key: '8',
        value: {
          checked: !!selectedItems?.includes(userId),
          handlePress: handlePress(userId),
        },
        type: checkbox,
      },
    ];

    if (isPharmacist) {
      renderingData = renderingData.filter(
        (inItem) =>
          !itemsToRemove.includes(inItem.key),
      );
    }

    if (isPharmacyAdmin) {
      renderingData = renderingData.filter(
        (inItem) =>
          !itemsToRemovePharmacyAdmin.includes(inItem.key),
      );
    }

    return {
      key: id,
      data: renderingData,
    };
  });

type IWeekVolumes = {
  key: {
    min: any;
    max: any;
  };
  label: string;
}[];

interface IFilterConfig {
  logos: Array<IDropdownObject>;
  softwareItems: Array<IDropdownObject>;
  weekVolumeItems: IWeekVolumes;
  weekendVolumeItems: IWeekVolumes;
  type: MiscType | HelpTypes;
}

export const filterConfig = ({
  logos,
  softwareItems,
  weekVolumeItems,
  weekendVolumeItems,
  type,
}: IFilterConfig) => {
  const filteredTypes = {
    '': [banner, weekVolume, weekendVolume],
    [MiscType.All]: [banner, weekVolume, weekendVolume],
    [HelpTypes.pharmacist]: [...itemsToRemove, weekVolume, weekendVolume],
    [HelpTypes.pharmacy]: [''],
    [HelpTypes.pharmacyAdmin]: [
      ...itemsToRemovePharmacyAdmin,
      weekVolume,
      weekendVolume,
    ],
  };

  const arr = [
    {
      key: '1',
      label: strings.userType,
      menuItems: UserTypesDropdown,
      name: 'userType',
      xs: 2,
      type: dropdown,
    },
    {
      key: banner,
      label: strings.banners,
      menuItems: logos,
      name: 'banners',
      xs: 2,
      multiple: true,
      imageMenu: true,
      type: dropdown,
      placeholder: strings.selectBanner,
      customMenuStyles: styles.bannerDropdown,
    },
    {
      key: '3',
      label: strings.user,
      placeholder: strings.enterNameEmail,
      name: 'userText',
      xs: 2,
      type: textField,
    },
    {
      key: '4',
      label: strings.area,
      placeholder: strings.formatString(strings.enterCred, strings.area),
      name: 'city',
      xs: 2,
      type: textField,
    },
    {
      key: software,
      label: strings.software,
      placeholder: strings.selectSoftware,
      menuItems: softwareItems,
      name: 'softwareIds',
      xs: 2,
      type: dropdown,
      multiple: true,
    },
    {
      key: weekVolume,
      label: strings.weekVolumes,
      menuItems: weekVolumeItems,
      name: 'weekVolume',
      xs: 2,
      type: dropdown,
    },
    {
      key: weekendVolume,
      label: strings.weekEndVolumes,
      menuItems: weekendVolumeItems,
      name: 'weekendVolume',
      xs: 2,
      type: dropdown,
    },
  ];

  return arr.filter(
    (item) =>
      !filteredTypes[type as keyof typeof filteredTypes].includes(item.key),
  );
};
